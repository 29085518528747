import React, { useMemo, useCallback } from "react";
import pt from 'prop-types';
import { motion } from 'framer-motion';
import { appendHtmlClass, getAnimationDuration, getAnimationEasing } from '@arturpol/style-guide/src/js/util';
import { Container, Box, Flex, FlexItem, Heading, Breakpoint, Lead, useBreakpoint } from '@arturpol/style-guide/src/components';
import { Ruler } from '../';
import { getArcMotionProps } from '../../util';
import './aboutbanner.scss';

const AboutBanner = ({ className, data, ...rest }) => {

    const isOn = true;

    const delay = getAnimationDuration('normal');
    const circleDuration = getAnimationDuration('slowest');
    const weDelay = delay + circleDuration;
    const weDuration = getAnimationDuration('slowest');

    const getCircleProps = useCallback((target, delta, duration, delay) => {

        const props = getArcMotionProps(target, delta, duration, delay);

        return {
            style: {
                ...props.style,
            },
            variants: {
                on: {
                    ...props.variants.on,
                    opacity: 1,
                    scale: 1,
                },
                off: {
                    ...props.variants.off,
                    opacity: 0,
                    scale: 0.33,
                },
            },
            transition: {
                ...props.transition,
                opacity: {
                    transition: getAnimationEasing('easeSoft'),
                    duration,
                }
            }
        }

    }, []);

    const marketProps = useMemo(() => {
        
        return getCircleProps({
            translateX: 689.444007,
            translateY: 974.072244,
            rotateZ: 0,
        },{
            translateX: -600,
            translateY: 150,
            rotateZ: -30,
        }, circleDuration, delay);

    }, [getCircleProps, circleDuration, delay]);

    const uxProps = useMemo(() => {
        
        return getCircleProps({
            translateX: 977.454978,
            translateY: 974.075746,
            rotateZ: 0,
        },{
            translateX: 450,
            translateY: 350,
            rotateZ: -30,
        }, circleDuration, delay);

    }, [getCircleProps, circleDuration, delay]);

    const techProps = useMemo(() => {
        
        return getCircleProps({
            translateX: 833.449492,
            translateY: 686.072244,
            rotateZ: 0,
        },{
            translateX: 350,
            translateY: -250,
            rotateZ: 30,
        }, circleDuration, delay);

    }, [getCircleProps, circleDuration, delay]);

    const weProps = useMemo(() => {

        return {
            style: {
                translateX: 980.937299, 
                translateY: 1013.333802,
            },
            variants: {
                on: {
                    opacity: 1,
                },
                off: {
                    opacity: 0,
                },
            },
            transition: {
                ease: getAnimationEasing('ease'),
                duration: weDuration,
                delay: weDelay,
            },
        };

    }, [weDelay, weDuration]);

    const config = useBreakpoint({
        s: {
            transform: `translate(625, -3350) scale(3, 3) rotate(33.000000)`,
            margin: [2, -2],
            blobOpacity: 0,
        },
        l: {
            transform: 'translate(1444.853704, 1425.285078) rotate(33.000000) translate(-1444.853704, -1425.285078) translate(375.353704, 420.785078)',
            margin: null,
            blobOpacity: 0.0657319568,
        }
    });

    className = appendHtmlClass(className, 'about-banner');

    return (
        <Box as="section" className={className} paddingTop={{'s': 12, 'l': 18}} isAnimated animate={isOn ? 'on' : 'off'} initial={'off'} {...rest}>
            <Container as="article">
                <Flex gapX={{ 'l': 'margin' }}>
                    <FlexItem span={{'s': 12, 'l': 4}}>
                        <Flex className="about-banner-text">
                            <FlexItem span={{'s': 12, 'mOnly': 8}} pos={{'mOnly': 2}}>
                                <Breakpoint mDown={
                                    <Heading importance={1} size={2} isAlternative>{data.pageTitle}</Heading>
                                } />
                                <Heading importance={2} size={6} isAlternative>{data.title}</Heading>
                                <Lead>{data.lead}</Lead>
                            </FlexItem>
                        </Flex>
                        <Box as="figure" margin={config.margin}>
                            <svg className="svg-main" viewBox="0 0 2889 2851" preserveAspectRatio="xMidYMin slice" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <title>{data.image.alt}</title>
                                <g id="svg" transform={config.transform} stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <path id="blob" fill="#A39A92" fillRule="nonzero" opacity={config.blobOpacity} transform="translate(1069.487450, 1004.072417) rotate(-28.000000) translate(-1069.487450, -1004.072417)" d="M458.126787,322.082858 C323.490283,345.258428 199.071867,473.749872 228.28622,606.999161 L228.28622,606.999161 C251.59716,713.749582 357.173066,780.035477 456.618579,825.786618 L456.618579,825.786618 C556.064094,871.53776 666.407668,915.175155 722.791128,1008.94076 L722.791128,1008.94076 C797.035659,1132.67814 748.450093,1293.33135 785.686712,1432.62034 L785.686712,1432.62034 C817.625436,1551.82755 917.29856,1650.42532 1037.02646,1681.15314 L1037.02646,1681.15314 C1063.13652,1687.87249 1090.00337,1691.27006 1116.94586,1691.57242 L1116.94586,1691.57242 L1125.11942,1691.57242 C1218.81267,1690.51649 1312.42982,1652.09046 1377.4392,1584.52298 L1377.4392,1584.52298 C1481.65096,1476.03754 1505.11038,1314.8556 1578.89809,1183.87227 L1578.89809,1183.87227 C1627.78718,1097.05335 1698.16986,1024.72974 1762.27096,948.329312 L1762.27096,948.329312 C1826.37208,872.004383 1886.23495,787.752416 1907.34895,690.439368 L1907.34895,690.439368 C1958.35552,456.782364 1744.02498,312.50879 1558.532,452.927814 L1558.532,452.927814 C1311.3594,639.926695 1103.31089,542.082719 850.231776,414.115684 L850.231776,414.115684 C748.13736,362.475891 636.582863,316.572417 523.89364,316.572417 L523.89364,316.572417 C502.02179,316.572417 480.074277,318.308335 458.126787,322.082858"></path>
                                    <motion.g id="market" {...marketProps}>
                                        <ellipse id="market-shape" fill="#000000" opacity="0.165" cx="288.010971" cy="288.007005" rx="288.010971" ry="288.003503"></ellipse>
                                        <text className="svg-text" id="market-text" transform="translate(165.282266, 338.095416) rotate(-33.000000) translate(-165.282266, -338.095416)">
                                            <tspan x="50.782266" y="324.595416">{data.image.shape0Line0}</tspan>
                                            <tspan x="50.782266" y="377.095416">{data.image.shape0Line1}</tspan>
                                        </text>
                                    </motion.g>
                                    <motion.g id="ux" {...uxProps}>
                                        <ellipse id="ux-shape" fill="#000000" opacity="0.33" cx="288.010971" cy="288.003503" rx="288.010971" ry="288.003503"></ellipse>
                                        <text className="svg-text" id="ux-text" transform="translate(404.088558, 420.219819) rotate(-33.000000) translate(-404.088558, -420.219819) ">
                                            <tspan x="271.588558" y="406.719819">{data.image.shape1Line0}</tspan>
                                            <tspan x="271.588558" y="459.219819">{data.image.shape1Line1}</tspan>
                                        </text>
                                    </motion.g>
                                    <motion.g id="tech" {...techProps}>
                                        <ellipse id="tech-shape" fill="#000000" opacity="0.495" cx="288.010971" cy="288.003503" rx="288.010971" ry="288.003503"></ellipse>
                                        <text className="svg-text" id="tech-text" transform="translate(181.393182, 122.438610) rotate(-33.000000) translate(-181.393182, -122.438610) ">
                                            <tspan x="62.3931819" y="134.93861">{data.image.shape2Line0}</tspan>
                                        </text>
                                    </motion.g>
                                    <motion.g id="we" {...weProps}>
                                        <path d="M141.485257,0.000576265045 C217.319454,43.900196 271.27268,121.442654 282.965562,212.234001 C241.188629,235.897529 192.912057,249.400436 141.483966,249.400436 C90.0557252,249.400436 41.7790214,235.89745 0.00100167756,212.241018 C11.6913639,121.472847 65.6125985,43.9517708 141.407026,0.0443837162 Z" id="we-shape" fill="#FFD740"></path>
                                        <text className="svg-text svg-text-intersect" id="we-text" transform="translate(132.813291, 145.923890) rotate(-33.000000) translate(-132.813291, -145.923890) ">
                                            <tspan x="81.3132911" y="165.42389">{data.image.shapeIntersection}</tspan>
                                        </text>
                                    </motion.g>
                                </g>
                            </svg>
                        </Box>
                        <Flex className="about-banner-text">
                            <FlexItem span={{'s': 12, 'mOnly': 8}} pos={{'mOnly': 2}}>
                                <Breakpoint l={<Ruler />} />
                                {data.content}
                            </FlexItem>
                        </Flex>
                    </FlexItem>
                </Flex>
            </Container>
        </Box>
    );

}

AboutBanner.propTypes = {
    data: pt.object.isRequired,
};

AboutBanner.defaultProps = {
    
};

export default AboutBanner;