import React from "react";
import pt from "prop-types";
import { Box, Container } from '@arturpol/style-guide/src/components';
import { appendHtmlClass } from '@arturpol/style-guide/src/js/util';
import './callout.scss';

const Callout = ({ className, children, ...rest }) => {

    className = appendHtmlClass(className, 'callout');
    const border = ['solid', 'gray', -1];
    
	return (
		<Box as="aside" className={className} paddingTop={10} paddingBottom={10} align="center" borderTop={{ 'm': border }} borderBottom={{ 'm': border }} mask="xy" {...rest}>
            <Container>
                {children}
            </Container>
        </Box>
	);
}

Callout.propTypes = {
	children: pt.node.isRequired,
};

export default Callout;