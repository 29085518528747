import React, { useCallback, useMemo, useState, useRef, useEffect } from "react";
import { Box, Breakpoint, Element, Flex, FlexItem } from '@arturpol/style-guide/src/components';
import { appendHtmlClass, getSpaceValue, getAnimationDuration, getAnimationEasing } from '@arturpol/style-guide/src/js/util';
import getConfig from '@arturpol/style-guide/src/js/settings';
import { Button, Link, NavigationSocial } from '../../components';
import { useNavigation } from '../../util';
import './navigation.scss';
import { navigation } from '../../data/layout';

const NAV_MOBILE_OFFSET_RIGHT = 4;
const NAV_MOBILE_OFFSET_BOTTOM = 4;
const NAV_MOBILE_BUTTON_SIZE = 'xl';

const NavigationMobile = ({ className, ...rest }) => {

	className = appendHtmlClass(className, 'nav-mobile');
    const menuItems = useNavigation('page', navigation);
    const [isOpen, setIsOpen] = useState(false);
    const [windowWidth, setWindowWidth] = useState(0);
    const ref = useRef(null);
    const offsetRight = getSpaceValue(NAV_MOBILE_OFFSET_RIGHT);
    const offsetBottom = getSpaceValue(NAV_MOBILE_OFFSET_BOTTOM);
    const baseDuration = getAnimationDuration('normal');
    const itemDuration = getAnimationDuration('fastest');
    const ease = getAnimationEasing('ease');

    useEffect(() => {
        
        setWindowWidth(window.innerHeight > window.innerWidth ? window.innerHeight : window.innerWidth);

    }, [setWindowWidth]);

    const buttonWidth = useMemo(() => {
        
        const size = getConfig('BUTTON_SIZE');
        return size.hasOwnProperty(NAV_MOBILE_BUTTON_SIZE) ? size[NAV_MOBILE_BUTTON_SIZE].height : 0;

    }, []);

    const [buttonWidthPx, setButtonWidthPx] = useState(0);

    const updateButtonWidthPx = useCallback(() => {
        
        if(!buttonWidthPx) setButtonWidthPx(ref && ref.current ? ref.current.getBoundingClientRect().width : 0);

    }, [buttonWidthPx, setButtonWidthPx, ref]);

    useEffect(() => {
        
        updateButtonWidthPx();

    },[updateButtonWidthPx]);
    
    const onMenuOpen = useCallback(event => {
        
        updateButtonWidthPx();
        setIsOpen(true);

    }, [setIsOpen, updateButtonWidthPx]);

    const onMenuClose = useCallback(event => {
        
        setIsOpen(false);

    }, [setIsOpen]);

    const buttonAnimationProps = useMemo(() => {

        const style = {
            right: offsetRight,
            bottom: offsetBottom,
        };

        const visible = {
            opacity: 0,
        };

        const hidden = {
            opacity: 1,
        };

        const transition = {
            duration: baseDuration,
            ease,
        };

        return {
            isAnimated: true,
            style,
            variants: { visible, hidden },
            transition,
        };

    }, [offsetRight, offsetBottom, baseDuration, ease]);

    const backgroundAnimationProps = useMemo(() => {

        const scale = buttonWidthPx <= 0 ? 1 : Math.ceil(windowWidth / buttonWidthPx) * 2.5;

        const style = {
            right: offsetRight,
            bottom: offsetBottom,
        };

        const hidden = {
            opacity: 0,
            scale: 1,
            transitionEnd: {
                zIndex: -1,
            },
        };

        const visible = {
            zIndex: 32,
            scale,
            opacity: 1,
        };

        const transition = {
            duration: baseDuration,
            ease,
        };

        return {
            isAnimated: true,
            variants: { visible, hidden },
            style,
            transition,
        };

    }, [buttonWidthPx, offsetRight, offsetBottom, baseDuration, ease, windowWidth]);

    const contentAnimationProps = useMemo(() => {

        const style = {
            opacity: 0,
        };

        const hidden = {
            opacity: 0,
            transitionEnd: {
                zIndex: -1,
            },
        };

        const visible = {
            opacity: 1,
            zIndex: 48,
            transition: {
                when: 'beforeChildren',
                staggerChildren: itemDuration,
            },
        };

        const transition = {
            duration: baseDuration / 2,
            ease,
        };

        return {
            isAnimated: true,
            variants: { hidden, visible },
            style,
            transition,
        };

    }, [baseDuration, itemDuration, ease]);

    const itemAnimationProps = useMemo(() => {

        const visible = {
            opacity: 1,
            y: 0,
        };

        const hidden = {
            opacity: 0,
            y: getSpaceValue(-2),
        };

        const transition = {
            duration: itemDuration,
            ease,
        };

        return {
            isAnimated: true,
            variants: { visible, hidden },
            transition,
        };

    }, [itemDuration, ease]);
	
	return (
        <Breakpoint s={
            <Box as="aside" className={className} isAnimated animate={isOpen ? 'visible' : 'hidden'} {...rest}>
                <Button color="gray" className="nav-mobile-btn btn-open" isRound width="square" size={NAV_MOBILE_BUTTON_SIZE} iconLeft="bars" onClick={onMenuOpen} {...buttonAnimationProps} />
                <Box as="nav" className="nav-mobile-content theme-invert" padding={[NAV_MOBILE_OFFSET_BOTTOM, NAV_MOBILE_OFFSET_RIGHT]} {...contentAnimationProps}>
                    <Box as="ul" align="right" marginBottom={5}>
                    { 
                        menuItems.map(i => {

                            let cls = i.isActive ? 'current' : null;
                            if(i.isPartiallyActive) cls = appendHtmlClass(cls, 'current-partially');
                            
                            return (
                                <Element as="li" className={cls} key={`${i.order}`} {...itemAnimationProps}>
                                    <Link type="raw" title={i.hint} url={i.url} isPartiallyActive={i.isPartiallyActive} onClick={onMenuClose}>
                                        <Box className="nav-mobile-item-inner">{i.title}</Box>
                                    </Link>
                                </Element>
                            );

                        })
                    }
                    </Box>
                    <Flex alignItemsY="center">
                        <FlexItem span="auto" paddingRight={6}>
                            <NavigationSocial size={4} alignItemsX={{'s': 'space-between', 'm': 'flex-end'}} {...itemAnimationProps} />
                        </FlexItem>
                        <FlexItem span="shrink">
                            <Button color="white" kind="outline" className="nav-mobile-btn" isRound width="square" size={NAV_MOBILE_BUTTON_SIZE} iconLeft="times" onClick={onMenuClose} />
                        </FlexItem>
                    </Flex>
                </Box>
                <Box className="nav-mobile-bg" bg={['gray', -4, 5]} width={buttonWidth} height={buttonWidth} corner={5} ref={ref} {...backgroundAnimationProps} />
            </Box>
        } l={null} />
	);

}

export default NavigationMobile;