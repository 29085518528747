import React from "react";
import pt from "prop-types";
import { Flex, FlexItem, Breakpoint, BreakpointContext, breakpointSwitch } from '@arturpol/style-guide/src/components';
import { appendHtmlClass, getAnimationDuration, getAnimationEasing, getSpaceValue } from '@arturpol/style-guide/src/js/util';
import { SliderNavArrow, QuoteBox, SliderNavBullet } from '../';
import './quoteslider.scss';

class QuoteSlider extends React.PureComponent{

    static contextType = BreakpointContext;

    animationDurationOnMedium = 'slower';
    animationDurationOnSmall = 'normal';
    animationDeltaY = 2;
    dragIgnoreThreshold = 10;

    constructor(props){

        super(props);

        this.state = {
            slide: (props.data.length > 0 ? props.data[0].id : null),
            direction: 1,
            isScrollable: false,
        };

        this.onTouchMove = this.onTouchMove.bind(this);
        this.ref = React.createRef();

    }

    getBreakpointValue(options){
        
        return breakpointSwitch(options, this.context);

    }

    onArrowClick(isNext){

        this.moveSlide(isNext ? 1 : -1);

    }

    moveSlide(delta){

        this.setState((state, props) => {

            let index = props.data.findIndex(i => i.id === state.slide);
        
            if(index < 0) index = 0;
            else {
                
                let requestedFinite = index + delta;
                let requestedInfinite = requestedFinite;
                
                if(requestedFinite >= 0 && requestedFinite < props.data.length){
                    requestedInfinite = requestedFinite;
                }else if(requestedFinite >= props.data.length){
                    requestedInfinite = Math.abs(props.data.length - requestedFinite);
                    requestedFinite = props.data.length - 1;
                }else if(requestedFinite < 0){
                    requestedInfinite = props.data.length + requestedFinite;
                    requestedFinite = 0;
                }

                index = breakpointSwitch({'s': requestedFinite, 'm': requestedInfinite}, this.context);

            }
            
            return {
                slide: props.data[index].id,
            };

        });

    }

    onSlideChange(id){
        
        this.setState({
            slide: id,
        });

    }

    onDragEnd(event, info){
        
        const offset = this.getBreakpointValue({ 's': info.offset.x, 'm': info.offset.y });
        const direction = this.getBreakpointValue({ 's': -1, 'm': 1 });
        
        if(Math.abs(offset) < this.dragIgnoreThreshold) return false;
        const delta = (offset < 0 ? -1 : 1) * direction;
        this.setState({ direction: delta });
        this.moveSlide(delta);

    }

    onDragStart(event, info){
        
        const isScrollable = Math.abs(info.delta.y) < Math.abs(info.delta.x);

        this.setState({
            isScrollable,
        });

    }

    componentDidMount(){
        document.documentElement.addEventListener('touchmove', this.onTouchMove);
    }

    componentWillUnmount(){
        document.documentElement.removeEventListener('touchmove', this.onTouchMove);
    }

    onTouchMove(event){
        
        const contains = this.ref && this.ref.current && (this.ref.current === event.target || this.ref.current.contains(event.target));
        if(!this.state.isScrollable && contains) event.stopPropagation();
        
    }

    getSliderAnimationProps(){
        
        const slides = this.props.data;
        const isAnimated = true;
        const initial = false;
        const width = `${slides.length * 100}%`;
        const duration = getAnimationDuration(this.animationDurationOnSmall);
        const easing = getAnimationEasing('easeSoft');
        const index = slides.findIndex(i => i.id === this.state.slide);
        const animate = { left: `${index * -100}%` };

        return this.getBreakpointValue({'s': {
            isAnimated,
            width,
            initial,
            animate,
            transition: { 
                duration,
                easing,
            },
            drag: 'x',
            dragConstraints: { left: 0, right: 0, top: 0, bottom: 0 },
            onDragEnd: this.onDragEnd.bind(this),
            onDragStart: this.onDragStart.bind(this),
            dragDirectionLock: true,
        }, 'm': { width }});

    }

    getSlideAnimationProps(isCurrent = false){
        
        const initial = false;
        const isAnimated = true;
        let animate = 'hidden';
        let className = 'quote-slide';
        const duration = getAnimationDuration(this.animationDurationOnMedium);
        
        const variants = {
            hidden: { 
                order: 2,
                x: 0,
                opacity: 1,
            },
            visible: { 
                order: 1,
                x: 0,
                opacity: 1,
            },
        };
        
        if(isCurrent){
            
            className = appendHtmlClass(className, 'current');
            animate = 'visible';

        }

        return this.getBreakpointValue({'s': { 
            className,
            shadow: 3,
            margin: [1, 2],
            padding: [2, 3, 5],
        }, 'm': {
            isAnimated,
            className,
            initial,
            animate,
            variants,
            transition: { duration },
        }});

    }

    getQuoteAnimationProps(){

        const isAnimated = true;
        const duration = getAnimationDuration(this.animationDurationOnMedium);
        const deltaY = getSpaceValue(this.animationDeltaY);
        const negDeltaY = getSpaceValue(-1 * this.animationDeltaY);
        
        const hidden = { 
            opacity: 0, 
            transition: { 
                duration,
            },
        };
        
        const visible = { 
            opacity: 1, 
            transition: { 
                duration, 
                delay: duration,
            },
        };
        
        const variants = { hidden, visible };
        
        const paragraphVariants = {
            hidden: {
                ...hidden,
                y: deltaY,
                transitionEnd: { y: negDeltaY, },
            },
            visible: {
                ...visible,
                y: 0,
            },
        };
        
        const medium = {
            overwrites: {
                Quote: {
                    overwrites: {
                        Paragraph: {
                            isAnimated,
                            variants: paragraphVariants,
                            drag: 'y',
                            dragConstraints: { left: 0, right: 0, top: 0, bottom: 0 },
                            onDragEnd: this.onDragEnd.bind(this),
                        },
                        Footer: {
                            isAnimated,
                            variants,
                        }
                    },
                },
                Avatar: {
                    isAnimated,
                    variants, 
                }
            },
        };

        const small = {};

        return this.getBreakpointValue({ 'sOnly': small, 'm': medium, });

    }

    render(){
        
        let { data, className, ...rest } = this.props;
        const { slide } = this.state;
        const sliderProps = this.getSliderAnimationProps();
        
        className = appendHtmlClass(className, 'quote-slider');
        
        return (
            <>
                <Flex alignItemsY="center" className={className} {...rest}>
                    <Breakpoint m={
                        <SliderNavArrow span={2} isNext={false} onSlideChange={this.onArrowClick.bind(this, false)} paddingBottom={14}/>
                    } />
                    <FlexItem span={{ 'm': 8 }} mask="xy">
                        <Flex as="ul" className="quote-slider-list" alignItemsY="stretch" ref={this.ref} {...sliderProps}>
                            { data.map(i => {
                                
                                const isCurrent = slide === i.id;
                                const slideProps = this.getSlideAnimationProps(isCurrent);
                                const quoteProps = this.getQuoteAnimationProps();
                                
                                return (
                                    <FlexItem as="li" flex="1" key={i.id} {...slideProps}>
                                        <QuoteBox data={i} height="100%" {...quoteProps} />
                                    </FlexItem>
                                );

                            })}
                        </Flex>
                    </FlexItem>
                    <Breakpoint m={
                        <SliderNavArrow span={2} isNext={true} onSlideChange={this.onArrowClick.bind(this, true)} paddingBottom={14} />
                    } />
                </Flex>
                <Breakpoint sOnly={
                    <SliderNavBullet slides={data} current={slide} onSlideChange={this.onSlideChange.bind(this)} marginBottom={8} />
                } />
            </>
        );
    
    };

}

QuoteSlider.propTypes = {
	data: pt.arrayOf(pt.shape({
        id: pt.any.isRequired,
    })),
};

export default QuoteSlider;