import { useStaticQuery, graphql } from "gatsby";

export default function usePortfolio(){

	const portfolio = useStaticQuery(graphql`
        query PortfolioProjectQuery {
            mdx(fields: {slug: {eq: "/portfolio/"}}) {
                exports {
                    meta {
                        hint
                        title
                    }
                }
            }
        }      
    `);

	return portfolio.mdx.exports.meta;

};