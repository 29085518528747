import React from "react";
import pt from "prop-types";
import { Heading, Flex, FlexItem, Caption, Icon, Box, Breakpoint } from '@arturpol/style-guide/src/components';
import { appendHtmlClass } from '@arturpol/style-guide/src/js/util';
import { Callout, Button } from '../../components';
import '../../images/first-step.svg';
import './callout.scss';

const CalloutFeatures = ({ data, className, ...rest }) => {

    className = appendHtmlClass(className, 'callout-features');

	return (
		<Callout className={className} {...rest}>
            <Heading size={3} importance={5}>{data.title}</Heading>
            <Box as="nav" padding={[1, 0, 2]}>
                <Breakpoint s={
                    <Button width={'stretch'} url={data.cta.url} title={data.cta.hint}>{data.cta.label}</Button>
                } m={
                    <Button iconLeft="phone-alt" url={data.cta.url} title={data.cta.hint}>{data.cta.label}</Button>
                } />
            </Box>
            <Flex as="ul" className="features" alignItemsX="center" wrap="wrap" gapX="margin">
                {
                    data.features.map(i => (
                        <FlexItem as="li" span={{ 'sOnly': 6 }} key={i.text.toLowerCase()}>
                            <Caption align="left" color={i.isPositive ? null : ['gray', 1]}>
                                <Icon size={1} name={i.isPositive ? 'check' : 'times'} display="iblock" />{i.text}
                            </Caption>
                        </FlexItem>
                    ))
                }
            </Flex>
        </Callout>
	);
}

CalloutFeatures.propTypes = {
	data: pt.object,
};

export default CalloutFeatures;