import React from "react";
import pt from "prop-types";
import { Heading, Box } from '@arturpol/style-guide/src/components';
import { appendHtmlClass } from '@arturpol/style-guide/src/js/util';
import { Callout, Button } from '../../components';
import '../../images/secret-sauce.svg';
import './callout.scss';

const CalloutAbout = ({ className, data, ...rest }) => {

    className = appendHtmlClass(className, 'callout-about');

	return (
		<Callout className={className} {...rest}>
            <Box padding={{ 'sOnly': [0, 2] }}>
                <Heading size={3} importance={5}>{data.title}</Heading>
                {data.content}
                <Box as="nav" padding={[1, 0, 2]}>
                    <Button iconRight="long-arrow-alt-right" width={{ 'sOnly': 'stretch' }} url={data.cta.url} title={data.cta.hint}>{data.cta.label}</Button>
                </Box>
            </Box>
        </Callout>
	);
}

CalloutAbout.propTypes = {
	data: pt.object,
};

export default CalloutAbout;