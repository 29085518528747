import React from "react";
import pt from 'prop-types';

import { Icon } from '@arturpol/style-guide/src/components';

import './boxicon.scss';

const BoxIcon = (props) => <Icon as="div" className="icon-box" size={5} display="block" {...props} />

BoxIcon.propTypes = {
	name: pt.oneOfType([pt.string, pt.arrayOf(pt.string)]),
};

export default BoxIcon;