import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from "gatsby";

function escapeRegExp(string) {
    
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
    
}

export default function useNavigation(pageType = 'page', pages = []){
    
    const data = useStaticQuery(graphql`
		query NavQuery {
			allMdx {
				edges {
					node {
						exports {
							meta {
								hint
								order
                                title
								type
								isPartiallyActive
							}
						}
						fields {
							slug
						}
					}
				}
			}
		}`
	);

	const { pathname, search, hash } = useLocation();
	const reHash = new RegExp(`${escapeRegExp(hash)}$`);
	const url = `${pathname}${search}${hash}`;
    
    return pages.concat(
        data.allMdx.edges
        .filter(i => i.node.exports.meta.type === pageType)
        .map(i => {
                        
            return Object.assign({}, i.node.exports.meta, { 
				url: i.node.fields.slug,
				isPartiallyActive: (i.node.exports.meta.hasOwnProperty('isPartiallyActive') ? i.node.exports.meta.isPartiallyActive : false),
			});
			
        })
    ).map(i => {
		
		const isPartiallyActive = url !== '/' && i.isPartiallyActive && url.indexOf(i.url) === 0;
		const isActive = 
			isPartiallyActive
			|| (i.url === url)
			|| (hash.length > 1 && reHash.test(i.url));
			
        return Object.assign(i, { 
			isActive, 
			isPartiallyActive
		});
        
    }).sort((a,b) => a.order - b.order);

}