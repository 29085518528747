import React, { useEffect } from "react";
import { PropTypes as pt } from "prop-types";

const TemplateClient = ({render, ...props}) => {
    
    useEffect(() => {
        
        if(!render){
            
            let hash = window.location.pathname;
            let redirectUrl = `${window.location.protocol}//${window.location.host}/#${hash}`;
            window.location.replace(redirectUrl);

        }
        
    });

    return <>{render ? render(props) : null}</>;

};

TemplateClient.propTypes = {
    render: pt.func,
};

export default TemplateClient;
