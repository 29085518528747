import React, { useEffect } from "react";
import pt from 'prop-types';
import { motion } from 'framer-motion';
import { useTotalDuration, useAnimationProp } from '../../util';
import './offerfigure.scss';
import timeImage from '../../images/offer-design-time.jpg';
import activityImage from '../../images/offer-design-activity.jpg';
import summaryImage from '../../images/offer-design-summary.jpg';
import menuImage from '../../images/offer-design-menu.png';

const pointerClickDuration = 0.15;

const OfferFigureDesign = ({ duration }) => {

    let pointerTranslate = [
        0.5, 0.75,
    ];
    let pointerClick = [
        pointerTranslate[0] + pointerTranslate[1], pointerClickDuration / 2,
    ];
    let phoneTransform = [
        pointerClick[0] + pointerClick[1], 0.75,
    ];
    pointerClick.push(
        pointerClick[0] + pointerClick[1], pointerClick[1],
    );
    let menuTransition = [
        pointerClick[2] + pointerClick[3], phoneTransform[1] - pointerClick[3],
    ];
    pointerTranslate.push(
        menuTransition[0] + menuTransition[1] + 0.25, 0.5,
    );
    pointerClick.push(
        pointerTranslate[2] + pointerTranslate[3], pointerClick[1],
    );
    pointerClick.push(
        pointerClick[4] + pointerClick[5], pointerClick[1],
    );
    menuTransition.push(
        pointerClick[6] + pointerClick[7], menuTransition[1],
    );
    let summaryOpacity = [
        pointerClick[5] + pointerClick[6], menuTransition[3] / 2,
    ];
    let activityOpacity = [
        summaryOpacity[0], summaryOpacity[1],
    ];
    let activityBgOpacity = [
        summaryOpacity[0], menuTransition[3] * 1.5,
    ];
    let summaryBgOpacity = [
        activityBgOpacity[0], activityBgOpacity[1],
    ];
    phoneTransform.push(
        summaryBgOpacity[0] + summaryBgOpacity[1] / 2, phoneTransform[1],
    );
    pointerTranslate.push(
        phoneTransform[2] + phoneTransform[3] + 0.25, pointerTranslate[3],
    );
    pointerClick.push(
        pointerTranslate[4] + pointerTranslate[5], pointerClick[1],
    );
    pointerClick.push(
        pointerClick[8] + pointerClick[9], pointerClick[1],
    );
    let timeTransition = [
        pointerClick[10] + pointerClick[11], menuTransition[1],
    ];
    activityOpacity.push(
        timeTransition[0], timeTransition[1],
    );
    pointerTranslate.push(
        timeTransition[0] + timeTransition[1] - 0.25, 1,
    );
    pointerClick.push(
        pointerTranslate[6] + pointerTranslate[7], pointerClick[1],
    );
    pointerClick.push(
        pointerClick[12] + pointerClick[13], pointerClick[1],
    );
    timeTransition.push(
        pointerClick[14] + pointerClick[15], timeTransition[1],
    );
    summaryOpacity.push(
        timeTransition[2], timeTransition[3],
    );
    activityBgOpacity.push(
        timeTransition[2], timeTransition[3] * 1.5,
    );
    summaryBgOpacity.push(
        activityBgOpacity[2], activityBgOpacity[3],
    );
    pointerTranslate.push(
        activityBgOpacity[2], 0.5,
    );

    const totalDuration = useTotalDuration([
        pointerTranslate,
        pointerClick,
        menuTransition,
        summaryOpacity,
        activityOpacity,
        activityBgOpacity,
        summaryBgOpacity,
        phoneTransform,
        timeTransition,
    ]);

    useEffect(() => {

        const unsubscribe = duration.onChange((elapsed) => {
            
            if(elapsed >= totalDuration) duration.set(totalDuration - elapsed);

        });

        return () => unsubscribe();

    });

    const phoneStyle = {
        scale: useAnimationProp(duration, phoneTransform, [0.66, 1, 1, 0.66], ['ease', 'linear', 'ease']),
        translateX: useAnimationProp(duration, phoneTransform, [67, 132, 132, 67], ['ease', 'linear', 'ease']),
        translateY: useAnimationProp(duration, phoneTransform, [50, 65, 65, 50], ['ease', 'linear', 'ease']),
    };

    const pointerStyle = {
        translateX: useAnimationProp(duration, pointerTranslate, [20, 330, 330, 95, 95, 260, 260, 120, 120, 20], ['easeOut', 'linear', 'ease', 'linear', 'easeOut', 'linear', 'ease', 'linear', 'easeOut']),
        translateY: useAnimationProp(duration, pointerTranslate, [630, 5, 5, 165, 165, 5, 5, 740, 740, 630], ['ease', 'linear', 'easeOut', 'linear', 'ease', 'linear', 'easeOut', 'linear', 'ease']),
        scale: useAnimationProp(duration, pointerClick, [1, 0.6, 0.6, 1, 1, 0.6, 0.6, 1, 1, 0.6, 0.6, 1, 1, 0.6, 0.6, 1], ['ease', 'linear', 'ease', 'linear', 'ease', 'linear', 'ease', 'linear', 'ease', 'linear', 'ease', 'linear', 'ease', 'linear', 'ease']),
        opacity: useAnimationProp(duration, pointerClick, [0.66, 1, 1, 0.66, 0.66, 1, 1, 0.66, 0.66, 1, 1, 0.66, 0.66, 1, 1, 0.66], ['easeOut', 'linear', 'easeIn', 'linear', 'easeOut', 'linear', 'easeIn', 'linear', 'easeOut', 'linear', 'easeIn', 'linear', 'easeOut', 'linear', 'easeIn']),
    };

    const backdropStyle = {
        opacity: useAnimationProp(duration, menuTransition, [0, 1, 1, 0], ['linear', 'linear', 'linear']),
    };

    const menuStyle = {
        translateX: useAnimationProp(duration, menuTransition, [375, 0, 0, 375], ['easeOut', 'linear', 'easeIn']),
    };

    const activityStyle = {
        opacity: useAnimationProp(duration, activityOpacity, [0, 1, 1, 0], ['linear', 'linear', 'linear']),
    };

    const summaryStyle = {
        opacity: useAnimationProp(duration, summaryOpacity, [1, 0, 0, 1], ['linear', 'linear', 'linear']),
    };

    const summaryBgStyle = {
        opacity: useAnimationProp(duration, summaryBgOpacity, [1, 0, 0, 1], ['linear', 'linear', 'linear']),
    };

    const activityBgStyle = {
        opacity: useAnimationProp(duration, activityBgOpacity, [0, 1, 1, 0], ['linear', 'linear', 'linear']),
    };

    const timeStyle = {
        opacity: useAnimationProp(duration, timeTransition, [0, 1, 1, 0], ['linear', 'linear', 'linear']),
        translateY: useAnimationProp(duration, timeTransition, [-812, 0, 0, -812], ['easeOut', 'linear', 'easeIn']),
        scale: useAnimationProp(duration, timeTransition, [1.5, 1, 1, 1.5], ['easeOut', 'linear', 'easeIn']),
    };

    return (
        <svg viewBox="0 0 640 640" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>Human Application Design</title>
            <defs>
                <radialGradient cx="51.5942383%" cy="50%" fx="51.5942383%" fy="50%" r="119.445715%" gradientTransform="translate(0.515942,0.500000),scale(1.000000,0.500000),rotate(64.936130),translate(-0.515942,-0.500000)" id="radialGradient-1">
                    <stop stopColor="#693DA9" offset="0%"></stop>
                    <stop stopColor="#381C70" offset="100%"></stop>
                </radialGradient>
                <radialGradient cx="52.0227288%" cy="50.4581165%" fx="52.0227288%" fy="50.4581165%" r="118.414661%" gradientTransform="translate(0.520227,0.504581),scale(1.000000,0.500000),rotate(64.936130),translate(-0.520227,-0.504581)" id="radialGradient-2">
                    <stop stopColor="#346364" offset="0%"></stop>
                    <stop stopColor="#00C9CE" offset="99.9836101%"></stop>
                </radialGradient>
                <path d="M63.458278,25 L311.541722,25 C324.914503,25 329.763797,26.3923842 334.652686,29.0069906 C339.541574,31.6215969 343.378403,35.4584256 345.993009,40.3473144 C348.607616,45.2362033 350,50.0854969 350,63.458278 L350,748.541722 C350,761.914503 348.607616,766.763797 345.993009,771.652686 C343.378403,776.541574 339.541574,780.378403 334.652686,782.993009 C329.763797,785.607616 324.914503,787 311.541722,787 L63.458278,787 C50.0854969,787 45.2362033,785.607616 40.3473144,782.993009 C35.4584256,780.378403 31.6215969,776.541574 29.0069906,771.652686 C26.3923842,766.763797 25,761.914503 25,748.541722 L25,63.458278 C25,50.0854969 26.3923842,45.2362033 29.0069906,40.3473144 C31.6215969,35.4584256 35.4584256,31.6215969 40.3473144,29.0069906 C45.2362033,26.3923842 50.0854969,25 63.458278,25 Z" id="path-3"></path>
                <filter x="-29.2%" y="-11.2%" width="158.5%" height="124.9%" filterUnits="objectBoundingBox" id="filter-4">
                    <feOffset dx="0" dy="10" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                    <feGaussianBlur stdDeviation="30" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 1 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                </filter>
                <linearGradient x1="38.898545%" y1="-0.766818042%" x2="60.6640085%" y2="101.012642%" id="linearGradient-5">
                    <stop stopColor="#FFFFFF" stopOpacity="0.25" offset="0%"></stop>
                    <stop stopColor="#DDDDDD" stopOpacity="0" offset="100%"></stop>
                </linearGradient>
                <path d="M38.458278,-1.55376046e-15 L336.541722,-2.0735761e-15 C349.914503,1.20480743e-15 354.763797,1.39238417 359.652686,4.00699056 C364.541574,6.62159695 368.378403,10.4584256 370.993009,15.3473144 C373.607616,20.2362033 375,25.0854969 375,38.458278 L375,773.541722 C375,786.914503 373.607616,791.763797 370.993009,796.652686 C368.378403,801.541574 364.541574,805.378403 359.652686,807.993009 C354.763797,810.607616 349.914503,812 336.541722,812 L38.458278,812 C25.0854969,812 20.2362033,810.607616 15.3473144,807.993009 C10.4584256,805.378403 6.62159695,801.541574 4.00699056,796.652686 C1.39238417,791.763797 6.01853049e-16,786.914503 -1.03584031e-15,773.541722 L1.03584031e-15,38.458278 C-6.01853049e-16,25.0854969 1.39238417,20.2362033 4.00699056,15.3473144 C6.62159695,10.4584256 10.4584256,6.62159695 15.3473144,4.00699056 C20.2362033,1.39238417 25.0854969,9.02779573e-16 38.458278,-1.55376046e-15 Z" id="path-6"></path>
                <path d="M38.458278,-1.55376046e-15 L336.541722,-2.0735761e-15 C349.914503,1.20480743e-15 354.763797,1.39238417 359.652686,4.00699056 C364.541574,6.62159695 368.378403,10.4584256 370.993009,15.3473144 C373.607616,20.2362033 375,25.0854969 375,38.458278 L375,773.541722 C375,786.914503 373.607616,791.763797 370.993009,796.652686 C368.378403,801.541574 364.541574,805.378403 359.652686,807.993009 C354.763797,810.607616 349.914503,812 336.541722,812 L38.458278,812 C25.0854969,812 20.2362033,810.607616 15.3473144,807.993009 C10.4584256,805.378403 6.62159695,801.541574 4.00699056,796.652686 C1.39238417,791.763797 6.01853049e-16,786.914503 -1.03584031e-15,773.541722 L1.03584031e-15,38.458278 C-6.01853049e-16,25.0854969 1.39238417,20.2362033 4.00699056,15.3473144 C6.62159695,10.4584256 10.4584256,6.62159695 15.3473144,4.00699056 C20.2362033,1.39238417 25.0854969,9.02779573e-16 38.458278,-1.55376046e-15 Z" id="path-8"></path>
                <filter x="-3.6%" y="-1.7%" width="107.2%" height="103.3%" filterUnits="objectBoundingBox" id="filter-10">
                    <feGaussianBlur stdDeviation="10" in="SourceAlpha" result="shadowBlurInner1"></feGaussianBlur>
                    <feOffset dx="0" dy="5" in="shadowBlurInner1" result="shadowOffsetInner1"></feOffset>
                    <feComposite in="shadowOffsetInner1" in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowInnerInner1"></feComposite>
                    <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.0304851399 0" type="matrix" in="shadowInnerInner1"></feColorMatrix>
                </filter>
                <circle id="path-11" cx="266.5" cy="366.5" r="30.5"></circle>
                <filter x="-15.6%" y="-15.6%" width="131.1%" height="131.1%" filterUnits="objectBoundingBox" id="filter-12">
                    <feMorphology radius="1.5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
                    <feOffset dx="0" dy="3" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
                    <feGaussianBlur stdDeviation="8" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                    <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"></feComposite>
                    <feColorMatrix values="0 0 0 0 0.218444774   0 0 0 0 0.427904212   0 0 0 0 0.346325273  0 0 0 0.689248252 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                </filter>
                <filter x="-9.0%" y="-9.0%" width="118.0%" height="118.0%" filterUnits="objectBoundingBox" id="filter-13">
                    <feMorphology radius="2" operator="erode" in="SourceAlpha" result="shadowSpreadInner1"></feMorphology>
                    <feGaussianBlur stdDeviation="3.5" in="shadowSpreadInner1" result="shadowBlurInner1"></feGaussianBlur>
                    <feOffset dx="0" dy="-2" in="shadowBlurInner1" result="shadowOffsetInner1"></feOffset>
                    <feComposite in="shadowOffsetInner1" in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowInnerInner1"></feComposite>
                    <feColorMatrix values="0 0 0 0 0.219607843   0 0 0 0 0.42745098   0 0 0 0 0.345098039  0 0 0 0.281687063 0" type="matrix" in="shadowInnerInner1"></feColorMatrix>
                </filter>
            </defs>
            <g id="design-imgs" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <motion.rect id="bg2" style={activityBgStyle} fill="url(#radialGradient-1)" x="0" y="0" width="640" height="1280"></motion.rect>
                <motion.rect id="bg1" style={summaryBgStyle} fill="url(#radialGradient-2)" x="0" y="0" width="640" height="1280"></motion.rect>
                <motion.g id="phone" style={phoneStyle}>
                    <g id="shadow">
                        <use fill="black" fillOpacity="1" filter="url(#filter-4)" xlinkHref="#path-6"></use>
                        <use fill="#FFFFFF" fillRule="evenodd" xlinkHref="#path-6"></use>
                    </g>
                    <mask id="mask-7" fill="white">
                        <use xlinkHref="#path-6"></use>
                    </mask>
                    <g id="bg" strokeLinejoin="round">
                        <path strokeOpacity="0.12377076" stroke="#FFFFFF" strokeWidth="22" d="M336.541722,-11 C353.029673,-11 358.854003,-8.89444694 364.840273,-5.69295462 C371.674469,-2.03798647 377.037986,3.32553075 380.692955,10.159727 C383.894447,16.1459972 386,21.9703274 386,38.458278 L386,38.458278 L386,773.541722 C386,790.029673 383.894447,795.854003 380.692955,801.840273 C377.037986,808.674469 371.674469,814.037986 364.840273,817.692955 C358.854003,820.894447 353.029673,823 336.541722,823 L336.541722,823 L38.458278,823 C21.9703274,823 16.1459972,820.894447 10.159727,817.692955 C3.32553075,814.037986 -2.03798647,808.674469 -5.69295462,801.840273 C-5.93651297,801.384858 -6.17370727,800.930363 -6.40409779,800.472055 L-6.6322041,800.012302 C-9.26660524,794.629604 -10.9549858,788.513464 -10.99907,774.151984 L-10.99907,774.151984 L-10.9990391,37.8524687 C-10.947844,21.8264921 -8.85492234,16.0720926 -5.69295462,10.159727 C-2.03798647,3.32553075 3.32553075,-2.03798647 10.159727,-5.69295462 C10.6151414,-5.93651292 11.069637,-6.17370717 11.5279443,-6.40409764 L11.9876974,-6.63220391 C17.3703947,-9.26660452 23.486533,-10.954985 37.8480061,-10.99907 L37.8480061,-10.99907 Z" fill="#FFFFFF" fillRule="evenodd"></path>
                        <path stroke="url(#linearGradient-5)" strokeWidth="25" d="M336.541722,-12.5 C352.028092,-12.5 358.32899,-10.6530858 363.992344,-7.82224667 L364.438282,-7.59673094 C364.809053,-7.4071239 365.178038,-7.21335648 365.547671,-7.01567442 C372.647137,-3.21883875 378.218839,2.35286327 382.015674,9.45232871 C382.213356,9.82196203 382.407124,10.1909471 382.596731,10.5617181 L382.822247,11.0076562 C385.653086,16.6710097 387.5,22.9719078 387.5,38.458278 L387.5,38.458278 L387.5,773.541722 C387.5,789.028092 385.653086,795.32899 382.822247,800.992344 L382.596731,801.438282 C382.407124,801.809053 382.213356,802.178038 382.015674,802.547671 C378.218839,809.647137 372.647137,815.218839 365.547671,819.015674 C365.178038,819.213356 364.809053,819.407124 364.438282,819.596731 L363.992344,819.822247 C358.32899,822.653086 352.028092,824.5 336.541722,824.5 L336.541722,824.5 L38.458278,824.5 C22.9719078,824.5 16.6710097,822.653086 11.0076562,819.822247 L10.5617181,819.596731 C10.1909471,819.407124 9.82196203,819.213356 9.45232871,819.015674 C2.35286327,815.218839 -3.21883875,809.647137 -7.01567442,802.547671 C-10.2624814,796.47667 -12.4488641,790.576894 -12.49907,774.154282 L-12.49907,774.154282 L-12.4990427,37.8517253 C-12.4512266,22.8062051 -10.6158379,16.5964919 -7.82224667,11.0076562 L-7.59673094,10.5617181 C-7.4071239,10.1909471 -7.21335648,9.82196203 -7.01567442,9.45232871 C-3.21883875,2.35286327 2.35286327,-3.21883875 9.45232871,-7.01567442 C15.5233284,-10.2624806 21.4231029,-12.4488631 37.8457065,-12.49907 L37.8457065,-12.49907 Z"></path>
                    </g>
                    <g mask="url(#mask-7)">
                        <motion.image id="time" style={timeStyle} x="0" y="0" width="375" height="812" href={timeImage} />
                    </g>
                    <motion.image id="activity" style={activityStyle} mask="url(#mask-7)" x="0" y="0" width="375" height="812" href={activityImage} /> 
                    <motion.image id="summary" style={summaryStyle} mask="url(#mask-7)" x="0" y="0" width="375" height="812" href={summaryImage} />
                    <motion.g id="backdrop" mask="url(#mask-7)" style={backdropStyle}>
                        <g id="bg" fill="#343031" opacity="0.903192935">
                            <rect id="color" x="0" y="0" width="375" height="812"></rect>
                        </g>
                    </motion.g>
                    <g mask="url(#mask-7)">
                        <motion.image id="menu" x="24" y="0" width="352" height="812" style={menuStyle} href={menuImage} />
                    </g>
                    <mask id="mask-9" fill="white">
                        <use xlinkHref="#path-6"></use>
                    </mask>
                    <g id="innerShadow" strokeLinejoin="round">
                        <use fill="black" fillOpacity="0" filter="url(#filter-10)" xlinkHref="#path-6"></use>
                        <use strokeOpacity="0.169525787" stroke="#000000" strokeWidth="2" xlinkHref="#path-6"></use>
                    </g>
                    <motion.g id="pointer" style={pointerStyle}>
                        <use fill="black" fillOpacity="0" filter="url(#filter-12)" xlinkHref="#path-11"></use>
                        <use fillOpacity="0.8" fill="#74D3AE" fillRule="evenodd" xlinkHref="#path-11"></use>
                        <use fill="black" fillOpacity="1" filter="url(#filter-13)" xlinkHref="#path-11"></use>
                        <use strokeOpacity="0.5" stroke="#74D3AE" strokeWidth="3" xlinkHref="#path-11"></use>
                    </motion.g>
                </motion.g>
            </g>
        </svg>
    );

};

OfferFigureDesign.propTypes = {
    animate: pt.bool,
};

OfferFigureDesign.defaultProps = {
    animate: false,
};

export default OfferFigureDesign;