import React from "react";
import pt from "prop-types";
import { Flex, FlexItem, Image } from '@arturpol/style-guide/src/components';
import { appendHtmlClass } from '@arturpol/style-guide/src/js/util';

const ProjectImageDouble = ({ className, images, span, ...rest }) => {
    
    className = appendHtmlClass(className, 'project-image-double');

    return (
        <Flex className={className} gapX={{'m': 'margin'}} alignItemsX="center" wrap="wrap" {...rest}>
            {
                images.map((i, index) => (
                    <FlexItem as="figure" span={{'m': span}} marginBottom={{'s': 2, 'm': 3}} key={index}>
                        <Image {...i} />
                    </FlexItem>
                ))
            }
        </Flex>
    );

}

ProjectImageDouble.propTypes = {
	images: pt.arrayOf(pt.object).isRequired,
};

ProjectImageDouble.defaultProps = {
    span: 6,
};

export default ProjectImageDouble;