import React from "react";

import { Ruler as SfhRuler, Breakpoint } from '@arturpol/style-guide/src/components';

const width = 6;
const pageMarginOnSmall = -2;

const Ruler = (props) => <SfhRuler width={width} {...props} />
const RulerForSmallOnly = (props) => <Breakpoint sOnly={<SfhRuler width={width} marginLeft={pageMarginOnSmall} {...props} />} />

export { 
    Ruler, 
    RulerForSmallOnly
};