import React from "react";
import pt from "prop-types";
import { FlexItem, Link, Icon } from '@arturpol/style-guide/src/components';
import { appendHtmlClass } from '@arturpol/style-guide/src/js/util';
import './slider.scss';
import { text } from  '../../data/global';

const SliderNavArrow = ({ isNext, onSlideChange, className, ...rest }) => {

	className = appendHtmlClass(className, 'slider-nav-arrow');

	return (
		<FlexItem as="nav" className={className} {...rest}>
            <Link type="raw" title={isNext ? text.slideNextLinkHint : text.slidePrevLinkHint} onClick={onSlideChange}>
                <Icon name={isNext ? 'chevron-right' : 'chevron-left'} size={5} display="iblock" color={['gray']}></Icon>
            </Link>
        </FlexItem>
	);
}

SliderNavArrow.propTypes = {
	isNext: pt.bool,
	onSlideChange: pt.func,
};

export default SliderNavArrow;