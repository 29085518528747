import ReactDOM from 'react-dom';

export function getElementSize(element){
    
    const node = ReactDOM.findDOMNode(element);
    
    return {
        width: node.offsetWidth,
        height: node.offsetHeight,
    };

}

export function debounce(fn, ms = 1000){
    
    let timer;

    return _ => {
        
        clearTimeout(timer);

        timer = setTimeout(_ => {
            timer = null;
            fn.apply(this, arguments);
        }, ms);

    };

}

export function encode(data){
    
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&');

}