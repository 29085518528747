import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import { Caption, Box, Flex, Paragraph, Icon, Link, Breakpoint } from '@arturpol/style-guide/src/components';
import { appendHtmlClass, getAnimationDuration, getAnimationEasing, 
	getSpaceValue, getPortalTarget, isServer } from '@arturpol/style-guide/src/js/util';
import { Button, SecondaryButton } from '../';
import './cookie.scss';
import { cookie } from '../../data/layout';

const LOCAL_STORAGE_KEY = 'cookies-accepted';

const Cookie = ({ className, ...rest }) => {

	className = appendHtmlClass(className, 'cookie-policy');
	const [isAlertShown, setIsAlertShown] = useState(false);

	useEffect(() => {
		
		const areCookiesAccepted = localStorage.getItem(LOCAL_STORAGE_KEY);
		setIsAlertShown(!areCookiesAccepted);

	}, [setIsAlertShown]);

	if(isServer()) return null;
	
	const accept = () => {
		
		setIsAlertShown(false);
		localStorage.setItem(LOCAL_STORAGE_KEY, true);

	};

	const currentAnimation = isAlertShown ? 'show' : 'hide';

	const animationProps = {
		isAnimated: true,
		variants: {
			show: {
				opacity: 1,
				y: 0,
			},
			hide: {
				opacity: 0,
				y: getSpaceValue(4),
				transition: {
					duration: getAnimationDuration('slow'),
					ease: getAnimationEasing('easeIn'),
				},
				transitionEnd: {
					display: 'none',
				},
			},
		},
		initial: currentAnimation,
		animate: currentAnimation,
	};

	const children = (
		<>
			<Breakpoint m={<Icon className="icon-cookie" name="cookie-bite" size={3} color={['secondary', -2]} />} />
			<Breakpoint 
				sOnly={<Caption>{cookie.message}</Caption>}
				m={<Paragraph>{cookie.message}</Paragraph>} 
			/>
			<Button type="button" size={'s'} color="gray" title={cookie.acceptButton.hint} onClick={accept}>
				{cookie.acceptButton.title}
			</Button>
			<Breakpoint sOnly={
				<Link className="cookie-more" title={cookie.moreButton.hint} url={cookie.moreButton.url} shouldOpenNewTab>
					{cookie.moreButton.title}
				</Link>
			} m={
				<SecondaryButton linkAs={Link} size={'s'} title={cookie.moreButton.hint} url={cookie.moreButton.url} shouldOpenNewTab>
					{cookie.moreButton.title}
				</SecondaryButton>
			} />
			<Link className="link-close" title={cookie.closeButton.hint} type="raw" onClick={accept}>
				<Icon name="times" size={3} color={['gray', 0]} />
			</Link>
		</>
	);

	const wrapperProps = {
		'as': 'aside',
		className,
		shadow: 4,
		padding: {'sOnly': [2], 'm': [1.5, 2]},
		bg: ['primary', 4],
		...animationProps,
		...rest,
	};

	const content = (
		<Breakpoint sOnly={
		<Box {...wrapperProps}>{children}</Box>
		} m={
			<Flex alignItemsY="center" {...wrapperProps}>{children}</Flex>
		} />
	);

	return ReactDOM.createPortal(content, getPortalTarget());
    
};

export default Cookie;