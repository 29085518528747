import React from "react";
import { Box, Heading, Flex, FlexItem, Breakpoint, Container, Paragraph, Text, Small, Element, Image, Icon } from '@arturpol/style-guide/src/components';
import { CalloutFeatures, Seo, Ruler, Link, BoxIcon, RulerForSmallOnly, Button, SecondaryButton, Slider, AboutBanner } from '../../components';
import blob3 from '../../images/blob3.svg';
import blob2 from '../../images/blob2.svg';
import './about.scss';
import * as global from '../../data/global';

const TemplateAbout = ({ meta, banner, process, team, faq, rules, contact }) => {

    const data = {...banner, pageTitle: meta.title};

    return (
        <>
            <Seo title={meta.seoTitle} description={meta.seoDescription} />
            <AboutBanner data={data} />
            <Box as="section" className="about-process" paddingBottom={6}>
                <Container>
                    <Flex>
                        <FlexItem span={{'mOnly': 6}} pos={{'mOnly': 2}}>
                            <Ruler marginLeft={{'s': -2, 'm': 0}} marginTop={3} />
                            <Element as="hgroup">
                                <Heading importance={4} size={2} isAlternative>{process.title}</Heading>
                                <Heading className="about-process-title" importance={3} size={4}>{process.subtitle}</Heading>
                            </Element>
                            <Flex as="ul" className="about-process-list" gapX={{'l': 'margin'}} wrap="wrap" paddingTop={{'s': 3, 'l': 9}}>
                                {
                                    process.steps.map((i, index) => (
                                        <FlexItem as="li" flex={{'l': '1'}} key={index}>
                                            <Element as="article">
                                                <Heading importance={4} size={3} isAlternative>{i.title}</Heading>
                                                <Box borderLeft={{'mDown': ['solid', 'gray', 3]}} paddingLeft={{'mDown': 4}} marginLeft={{'mDown': -4}} marginRight={{'mDown': 2}} paddingBottom={1} marginBottom={2} marginTop={{'l': 3}}>
                                                    <Box as="figure" marginBottom={3}>
                                                        <Image {...i.img} />
                                                    </Box>
                                                    <Paragraph dangerouslySetInnerHTML={{__html: i.content}}></Paragraph>
                                                </Box>
                                            </Element>
                                        </FlexItem>
                                    ))
                                }
                            </Flex>
                        </FlexItem>
                    </Flex>
                </Container>
            </Box>
            <CalloutFeatures className="callout-large" data={global.callout.features} paddingTop={8} paddingBottom={8} borderTop={null} />
            <Box as="section" className="about-team" align="center" paddingTop={10} paddingBottom={{'m': 4}}>
                <Breakpoint m={<img className="blob-about-team-1" src={blob3} alt="" />} />
                <Breakpoint m={<img className="blob-about-team-2" src={blob2} alt="" />} />
                <Container>
                    <Heading importance={4} size={4}>{team.title}</Heading>
                    <Flex as="ul" className="about-team-list" gapX={{'m': 'both'}} alignItemsX="center" wrap="wrap" marginTop={8}>
                        {
                            team.people.map(i => (
                                <FlexItem as="li" span={{'s': 12, 'm': 5, 'l': 4}} padding={{'m': [0, 5]}} marginBottom={4} key={i.id}>
                                    <Element as="article">
                                        <Box as="figure">
                                            <Box className="team-image" padding={1} border={['solid', 'gray', 2]} bg={['white']}>
                                                <Image {...i.img} />
                                                <Link type="raw" className="linkedin" url={i.linkedInLink.url} title={i.linkedInLink.hint} shouldOpenNewTab>
                                                    <Icon name={['brands', 'linkedin-in']} color={['gray']} size={2} display="block" />
                                                </Link>
                                            </Box>
                                            <Element as="figcaption">
                                                <Text as="div" className="name">{i.name}</Text>
                                                <Small>{i.title}</Small>
                                            </Element>
                                        </Box>
                                        <Paragraph>{i.bio}</Paragraph>
                                    </Element>
                                </FlexItem>
                            ))
                        }
                        <Breakpoint m={<BoxIcon name="plus" />} />
                    </Flex>
                </Container>
            </Box>
            <Box as="section" className="about-faq" paddingTop={{'m': 4}} paddingBottom={{'m': 6}}>
                <Container>
                    <RulerForSmallOnly />
                    <Flex gapX={{'m': 'margin'}} alignItemsX="center">
                        <FlexItem as="article" span={{'s': 12, 'm': 10, 'l': 8}} padding={{'m': [8, 8, 6, 8]}} border={{'s': ['solid', 'white'], 'm': ['solid', 'gray', 2]}} bg={['white']}>
                            <Heading important={5} size={3}>{faq.title}</Heading>
                            <Box className="faq-columns" marginTop={{'s': 3, 'm': 5}}>
                                {
                                    faq.questions.map((i, index) => (
                                        <Box className="faq-item" marginBottom={3} key={index}>
                                            <Heading importance={4} size={4}>{i.title}</Heading>
                                            <Paragraph dangerouslySetInnerHTML={{__html: i.content}}></Paragraph>
                                        </Box>
                                    ))
                                }
                            </Box>
                        </FlexItem>
                    </Flex>
                </Container>
            </Box>
            <Box as="section" className="about-rules" padding={{'l': [6, 0]}}>
                <Container>
                    <RulerForSmallOnly />
                    <Heading importance={5} size={4} align={{'l': 'center'}}>{rules.title}</Heading>
                    <Slider data={rules.items} marginTop={{'s': 4, 'l': 6}} marginLeft={{'mDown': -2}} marginRight={{'mDown': -2}} shadow={{'l': 3}}>
                        {(data, index) => (
                            <Flex as="article" flow="column" alignItemsY="stretch" height="100%" shadow={{'mDown': 3}} bg={['white']} paddingBottom={{'mDown': 2}} margin={{'mOnly': [0, 24]}}>
                                <FlexItem as="figure" span="shrink">
                                    <Image {...data.img} />
                                </FlexItem>
                                <FlexItem span="auto" padding={[2, 2, 0]}>
                                    <Heading importance={5} size={3} isAlternative={typeof data.cta !== 'object'}>{data.title}</Heading>
                                    <Paragraph>{data.content}</Paragraph>
                                </FlexItem>
                                {data.cta && (
                                    <FlexItem span="shrink" padding={[0, 2]}>
                                        <Button size="s" width="stretch" url={data.cta.url} title={data.cta.hint}>{data.cta.label}</Button>
                                    </FlexItem>
                                )}
                            </Flex>
                        )}
                    </Slider>
                </Container>
            </Box>
            <Box as="section" className="about-contact" padding={[4, 0, 8]}>
                <Container>
                    <RulerForSmallOnly />
                    <Flex gapX={{'m': 'margin'}} alignItemsX="center" wrap="wrap">
                        <FlexItem as="aside" span={{'s': 12, 'm': 5, 'l': 3}} align={{'m': 'right'}} marginBottom={2}>
                            <Heading importance={4} size={2}>{contact.details.title}</Heading>
                            {contact.details.content}
                        </FlexItem>
                        <FlexItem as="aside" span={{'s': 12, 'm': 5, 'l': 3}} marginBottom={2}>
                            <Heading importance={4} size={2} isAlternative>{contact.company.title}</Heading>
                            {
                                contact.company.entities.map((i, index) => (
                                    <Box as="address" marginBottom={2} key={index}>
                                        <Small>
                                            <Element as="div">{i.name}</Element>
                                            <Element as="div">{i.address}</Element>
                                            <Element as="div">{i.vat}</Element>
                                        </Small>
                                    </Box>
                                )) 
                            }
                        </FlexItem>
                    </Flex>
                    <Box padding={[2, 0]} align={{'m': 'center'}}>
                        <SecondaryButton iconRight="long-arrow-alt-right" url={contact.cta.url} title={contact.cta.hint}>{contact.cta.label}</SecondaryButton>
                    </Box>
                </Container>
            </Box>
        </>
    );

};

export default TemplateAbout;