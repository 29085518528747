import React from "react";
import pt from 'prop-types';
import { appendHtmlClass } from '@arturpol/style-guide/src/js/util';
import { Box, Heading } from '@arturpol/style-guide/src/components';
import './section.scss';

const Section = ({ title, subtitle, children, className, ...rest }) => {

    className = appendHtmlClass(className, 'section');

    return (
        <Box as="section" className={className} marginBottom={6} {...rest}>
            {( subtitle || title) && 
                <Box as="hgroup" className="section-heading">
                    {subtitle && <Heading as="div" size={2} isAlternative>{subtitle}</Heading>}
                    {title && <Heading importance={3} size={4}>{title}</Heading>}
                </Box> 
            }
            {children}
        </Box>
    );

};

Section.propTypes = {
    title: pt.node,
    subtitle: pt.node,
    children: pt.node,
};

Section.defaultProps = {
    
};

export default Section;