import React from "react";
import { Box, Heading, Container, Flex, FlexItem } from '@arturpol/style-guide/src/components';
import { CalloutAbout, Seo, Ruler, BoxIcon, OfferPackage, OfferBanner, } from '../../components';
import './offer.scss';
import * as global from '../../data/global';

const TemplateOffer = ({ meta, banner, packages }) => {

    const data = {...banner, pageTitle: meta.title, };

    return (
        <>
            <Seo title={meta.seoTitle} description={meta.seoDescription} />
            <OfferBanner data={data} />
            {
                packages.sections.map((i, index) => (
                    <Box as="section" className="offer-packages" marginBottom={8} key={i.id}>
                        <Container>
                            <Ruler margin={{'s': [0, 0, 6, -2], 'm': [0, 0, 6, 0]}} />
                            <Heading importance={4} size={{'s': 3, 'm': 4}}>{i.title}</Heading>
                            <Flex gapX="margin" alignItemsX="center">
                                <FlexItem span={{'mOnly': 7}}>
                                    {
                                        i.packages.map(j => (
                                            <OfferPackage data={j} text={packages.text} key={j.id} />
                                        ))
                                    }
                                    {index === 0 && <BoxIcon name="plus" />}
                                </FlexItem>
                            </Flex>
                        </Container>
                    </Box>
                ))
            }
            <CalloutAbout data={global.callout.about} marginTop={{'s': -6, 'm': -12}} paddingBottom={{'m': 4}} borderTop={null} borderBottom={null} />
        </>
    );

};

export default TemplateOffer;