import React, { useCallback } from "react";
import pt from "prop-types";
import { Link as GatsbyLink } from "gatsby";

import { Link as SfhLink } from '@arturpol/style-guide/src/components';

const Link = ({url, children, activeClass, isPartiallyActive, ...rest}) => {

    const isInternal = /^\/(?!\/)/.test(url);
    
    if(isInternal) {
        
        rest['as'] = {
            type: GatsbyLink,
            inheritProps: {
                href: 'to',
                className: true,
                title: true,
                target: true,
                style: true,
                activeClass: 'activeClassName',
                isPartiallyActive: 'partiallyActive',
                onClick: true,
            },
        };

        rest.activeClass = activeClass;
        rest.isPartiallyActive = isPartiallyActive;

    }
    
    return ( 
        <SfhLink url={url} {...rest}>
            {children}
        </SfhLink>
    );

};

Link.propTypes = {
    activeClass: pt.string,
    isPartiallyActive: pt.bool,
};

Link.defaultProps = {
    activeClass: 'current',
    isPartiallyActive: false,
}

const LinkExternal = ({children, url, shouldOpenNewTab, onClick, ...rest}) => {

    const onLinkClick = useCallback(e => {
        
        e.preventDefault();
        if(url) window.open(url);
        if(typeof onClick === 'function') onClick(e);

    }, [url, onClick]);

    return <SfhLink onClick={onLinkClick} shouldOpenNewTab {...rest}>{children}</SfhLink>;

};

export { Link, LinkExternal };