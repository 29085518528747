import { TemplateAbout } from "../../../../src/templates";
import { Heading, Element, Paragraph } from '@arturpol/style-guide/src/components';
import { Link } from "../../../../src/components";
import * as React from 'react';
export default {
  TemplateAbout,
  Heading,
  Element,
  Paragraph,
  Link,
  React
};