import React, { useEffect } from "react";
import pt from 'prop-types';
import { motion } from 'framer-motion';
import { useTotalDuration, useAnimationProp } from '../../util';
import './offerfigure.scss';
import businessCardFrontImage from '../../images/offer-business-1.png';
import businessCardBackImage from '../../images/offer-business-2.png';
import clipImage from '../../images/offer-clip.png';
import envelopeBackImage from '../../images/offer-envelope-back.png';
import envelopeFrontImage from '../../images/offer-envelope-front.png';
import ipadImage from '../../images/offer-ipad.png';
import letterImage from '../../images/offer-letter.png';
import paperclipsImage from '../../images/offer-paperclips.png';
import penImage from '../../images/offer-pen.png';
import pendriveImage from '../../images/offer-pendrive.png';
import slideImage from '../../images/offer-slide.png';

const OfferFigureBrandPro = ({ duration }) => {

    const productMoveDuration = 0.5;
    const productMoveDelay = 0.2;
    const watchDelay = 0.4;
    const moveDuration = 0.75;

    let paperclipsTransition = [
        watchDelay, productMoveDuration,
    ];
    let clipBTransition = [
        paperclipsTransition[0] + productMoveDelay, productMoveDuration,
    ];
    let clipATransition = [
        clipBTransition[0] + productMoveDelay, productMoveDuration,
    ];
    let slideTransition = [
        clipATransition[0] + productMoveDelay, productMoveDuration,
    ];
    let penTransition = [
        slideTransition[0] + productMoveDelay, productMoveDuration,
    ];
    let envelopeBackTransition = [
        penTransition[0] + productMoveDelay, productMoveDuration,
    ];
    let envelopeFrontTransition = [
        envelopeBackTransition[0] + productMoveDelay, productMoveDuration,
    ];
    let collectionTransition = [
        slideTransition[0] + slideTransition[1], moveDuration,
    ];
    collectionTransition.push(collectionTransition[0] + collectionTransition[1] + watchDelay, moveDuration);
    collectionTransition.push(collectionTransition[2] + collectionTransition[3] + watchDelay, moveDuration);
    collectionTransition.push(collectionTransition[4] + collectionTransition[5] + watchDelay, moveDuration);
    collectionTransition.push(collectionTransition[6] + collectionTransition[7] + watchDelay, moveDuration);
    envelopeBackTransition.push(collectionTransition[4] + collectionTransition[5], productMoveDuration);
    penTransition.push(envelopeBackTransition[2] + productMoveDelay, productMoveDuration);
    envelopeFrontTransition.push(penTransition[2] + productMoveDelay, productMoveDuration);
    slideTransition.push(envelopeFrontTransition[2] + productMoveDelay, productMoveDuration);
    clipBTransition.push(slideTransition[2] + productMoveDelay, productMoveDuration);
    clipATransition.push(clipBTransition[2] + productMoveDelay, productMoveDuration);
    paperclipsTransition.push(clipATransition[2] + productMoveDelay, productMoveDuration);
    collectionTransition.push(collectionTransition[8] + collectionTransition[9], watchDelay);

    const totalDuration = useTotalDuration([
        collectionTransition,
        envelopeBackTransition,
        envelopeFrontTransition,
        penTransition,
        slideTransition,
        clipATransition,
        clipBTransition,
        paperclipsTransition,
    ]);

    useEffect(() => {

        const unsubscribe = duration.onChange((elapsed) => {
            
            if(elapsed >= totalDuration) duration.set(totalDuration - elapsed);

        });

        return () => unsubscribe();

    });

    const collectionStyle = {
        translateX: useAnimationProp(duration, collectionTransition, [80, 420, 420, -68, -68, -28, -28, 576, 576, 80, 80, 80], ['easeOut', 'linear', 'easeOut', 'linear', 'easeOut', 'linear', 'easeOut', 'linear', 'easeOut', 'linear', 'easeOut']),
        translateY: useAnimationProp(duration, collectionTransition, [6, 304, 304, 496, 496, 176, 176, -188, -188, 6, 6, 6], ['ease', 'linear', 'ease', 'linear', 'ease', 'linear', 'ease', 'linear', 'ease', 'linear', 'ease']),
        scale: useAnimationProp(duration, collectionTransition, [0.9, 2, 2, 2.5, 2.5, 3, 3, 2.75, 2.75, 0.9, 0.9, 0.9], ['ease', 'linear', 'ease', 'linear', 'ease', 'linear', 'ease', 'linear', 'ease', 'linear', 'ease']),
        originX: '320px',
        originY: '320px',
    };

    const envelopeFrontStyle = {
        translateX: useAnimationProp(duration, envelopeFrontTransition, [0, -640, -640, 0], ['ease', 'linear', 'ease']),
        opacity: useAnimationProp(duration, envelopeFrontTransition, [1, 0, 0, 1], ['linear', 'linear', 'linear']),
    };

    const envelopeBackStyle = {
        translateX: useAnimationProp(duration, envelopeBackTransition, [0, 640, 640, 0], ['ease', 'linear', 'ease']),
        opacity: useAnimationProp(duration, envelopeBackTransition, [1, 0, 0, 1], ['linear', 'linear', 'linear']),
    };

    const penStyle = {
        translateX: useAnimationProp(duration, penTransition, [0, 640, 640, 0], ['ease', 'linear', 'ease']),
        opacity: useAnimationProp(duration, penTransition, [1, 0, 0, 1], ['linear', 'linear', 'linear']),
    };

    const slideStyle = {
        translateX: useAnimationProp(duration, slideTransition, [0, 640, 640, 0], ['ease', 'linear', 'ease']),
        opacity: useAnimationProp(duration, slideTransition, [1, 0, 0, 1], ['linear', 'linear', 'linear']),
    };

    const clipAStyle = {
        translateX: useAnimationProp(duration, clipATransition, [0, -640, -640, 0], ['ease', 'linear', 'ease']),
        opacity: useAnimationProp(duration, clipATransition, [1, 0, 0, 1], ['linear', 'linear', 'linear']),
    };
    
    const clipBStyle = {
        translateX: useAnimationProp(duration, clipBTransition, [0, -640, -640, 0], ['ease', 'linear', 'ease']),
        opacity: useAnimationProp(duration, clipBTransition, [1, 0, 0, 1], ['linear', 'linear', 'linear']),
    };

    const paperclipsStyle = {
        translateX: useAnimationProp(duration, paperclipsTransition, [0, -640, -640, 0], ['ease', 'linear', 'ease']),
        opacity: useAnimationProp(duration, paperclipsTransition, [1, 0, 0, 1], ['linear', 'linear', 'linear']),
    };

    return (
        <svg viewBox="0 0 640 640" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>Human Brand Design - Business</title>
            <defs>
                <radialGradient cx="50%" cy="0%" fx="50%" fy="0%" r="139.806569%" id="radialGradient-1">
                    <stop stopColor="#E7E4D7" offset="0%"></stop>
                    <stop stopColor="#D4D0BE" offset="100%"></stop>
                </radialGradient>
                <rect id="path-4" x="0" y="0" width="640" height="640"></rect>
            </defs>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect fill="#D4CEB8" x="0" y="0" width="640" height="640"></rect>
                <rect id="bg" fill="url(#radialGradient-1)" x="0" y="0" width="640" height="640"></rect>
                <motion.g id="collection" style={collectionStyle}>
                    <mask id="mask-5" fill="white">
                        <use xlinkHref="#path-4"></use>
                    </mask>
                    <motion.image id="clip-b" x="10" y="495" width="62" height="69" style={clipBStyle} href={clipImage}></motion.image>
                    <motion.image id="clip-a" x="10" y="455" width="62" height="69" style={clipAStyle} href={clipImage}></motion.image>
                    <motion.image id="paperclips" x="31" y="558" width="30" height="25" style={paperclipsStyle} href={paperclipsImage}></motion.image>
                    <image id="pendrive" x="422" y="210" width="62" height="98" href={pendriveImage}></image>
                    <motion.image id="pen" x="421" y="290" width="62" height="164" style={penStyle} href={penImage}></motion.image>
                    <image id="business2" x="327" y="226" width="110" height="74" href={businessCardBackImage}></image>
                    <image id="business1" x="221" y="226" width="110" height="74" href={businessCardFrontImage}></image>
                    <motion.image id="envelope-back" x="221" y="301" width="219" height="147" style={envelopeBackStyle} href={envelopeBackImage}></motion.image>
                    <motion.image id="envelope-front" x="0" y="323" width="219" height="125" style={envelopeFrontStyle} href={envelopeFrontImage}></motion.image>
                    <motion.image id="slide" x="70" y="452" width="398" height="159" style={slideStyle} href={slideImage}></motion.image>
                    <image id="ipad" x="197" y="0" width="298" height="249" href={ipadImage}></image>
                    <image id="letter" x="1" y="23" width="217" height="297" href={letterImage}></image>
                </motion.g>
            </g>
        </svg>
    ); 
};

OfferFigureBrandPro.propTypes = {
    animate: pt.bool,
};

OfferFigureBrandPro.defaultProps = {
    animate: false,
};

export default OfferFigureBrandPro;