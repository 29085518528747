import React from "react";
import pt from "prop-types";
import { Flex, FlexItem, Icon, Image, Heading } from '@arturpol/style-guide/src/components';
import { Link } from '../../components';
import { appendHtmlClass } from '@arturpol/style-guide/src/js/util';
import './projectnav.scss';

const ProjectNav = ({ isPrevious, className, data, ...rest }) => {
    
    className = appendHtmlClass(className, 'project-nav-box');
    className = appendHtmlClass(className, isPrevious ? 'previous' : 'next');

    return (
        <Link className={className} type="raw" url={data.url}>
            <Flex alignItemsY="stretch" padding={[1]} bg={['white']} shadow={3} align={isPrevious ? 'right' : 'left'} {...rest}>
                <FlexItem className="project-nav-text" span={7} paddingTop={1} order={isPrevious ? 0 : 1}>
                    <Heading size={{'s': 2, 'm': 1 }} importance={5}>{data.title}</Heading>
                    <Icon name={isPrevious ? 'long-arrow-alt-left' : 'long-arrow-alt-right'} size={3} display="block" color={['gray', -4]} />
                </FlexItem>
                <FlexItem span={5} padding={isPrevious ? [0, 0, 0, 3] : [0, 3, 0, 0]} order={isPrevious ? 1 : 0}>
                    <Image {...data.images.thumbnail} />
                </FlexItem>
            </Flex>
        </Link>
    );

}

ProjectNav.propTypes = {
    data: pt.object.isRequired,
    isPrevious: pt.bool,
};

ProjectNav.defaultProps = {
    isPrevious: false,
}

export default ProjectNav;
