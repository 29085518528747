import React from "react";
import pt from "prop-types";
import { FlexItem, Link, Flex, Box, Ruler } from '@arturpol/style-guide/src/components';
import { appendHtmlClass } from '@arturpol/style-guide/src/js/util';
import './slider.scss';
import { text } from  '../../data/global';

const SliderNavBullet = ({ slides, current, onSlideChange, className, ...rest }) => {

    className = appendHtmlClass(className, 'slider-nav-bullet');

    return ( slides.length > 0 && 
        <Box as="nav" className={className} padding={[2, 0]} {...rest}>
            <Flex as="ul">
                {
                    slides.map(i => (
                        <FlexItem as="li" className={`slider-nav-bullet-item${i.id === current ? ' current' : ''}`} width={3} height={3} padding={0.5} border={['solid', 'gray', 1]} marginRight={2} key={i.id}>
                            <Link type="raw" title={text.slideChangeLinkHint} onClick={() => onSlideChange(i.id)}></Link>
                        </FlexItem>
                    ))
                }
                <FlexItem as="li" className="ruler" span="auto">
                    <Ruler margin={[1.5, 0]} />
                </FlexItem>
            </Flex>
        </Box>
    );
}

SliderNavBullet.propTypes = {
	slides: pt.arrayOf(pt.shape({
        id: pt.any.isRequired,
    })),
    current: pt.any.isRequired,
    onSlideChange: pt.func,
};

export default SliderNavBullet;