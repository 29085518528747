import React from "react";
import pt from 'prop-types';
import { Link } from '@arturpol/style-guide/src/components';
import { emails } from '../../data/global';

const Email = ({ type, children, ...rest }) => {

    const email = emails[type];
    return <Link url={`mailto:${email}`} {...rest}>{children ? children : email}</Link>;

};

Email.propTypes = {
    type: pt.oneOf(Object.keys(emails)),
};

Email.defaultProps = {
    type: emails.contact,
};

export default Email;