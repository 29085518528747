import { TemplateLegal } from "../../../../src/templates";
import { Heading, Paragraph, List, ListItem } from '@arturpol/style-guide/src/components';
import { Section, Ruler } from "../../../../src/components";
import * as React from 'react';
export default {
  TemplateLegal,
  Heading,
  Paragraph,
  List,
  ListItem,
  Section,
  Ruler,
  React
};