import { useStaticQuery, graphql } from "gatsby";

export default function useProjects(limit = -1){

	const data = useStaticQuery(graphql`
		query ProjectQuery {
			allMdx(filter: {exports: {meta: {type: {eq: "project"}, published: {eq: true}}}}, sort: {fields: exports___meta___order, order: ASC}) {
				edges {
					node {
						id
						exports {
							meta {
								title
								hint
							}
							content {
								tags
								images{
									hero {
										src
										density
										alt
										breakpoint
									}
									thumbnail {
										src
										density
										alt
									}
								}
								excerpt
							}
						}
						fields {
							slug
						}
					}
				}
			}
		}
	`);

	return data.allMdx.edges.map(i => ({
		...i.node.exports.content,
		...i.node.exports.meta,
		url: i.node.fields.slug,
		id: i.node.id,
	})).filter((i, index) => limit < 0 || index < limit);

}