import React from "react";
import { Paragraph } from '@arturpol/style-guide/src/components';
import { Link } from '../components';

export const fontStylesheetUrl = 'https://fonts.googleapis.com/css?family=Raleway:300,500,600,700|Red+Hat+Text:400,400i,500&display=swap';

export const navigation = [{
    title: 'Contact',
    hint: 'Contact',
    url: '#/contact/',
    order: 128,
}];

export const navigationSocial = [{
    icon: 'facebook',
    hint: 'See us on Facebook',
    url: 'https://www.facebook.com/SystemsForHumans',
},{
    icon: 'dribbble',
    hint: 'See us on Dribbble',
    url: 'https://dribbble.com/systemsforhumans',
},{
    icon: 'behance',
    hint: 'See us on Behance',
    url: 'https://www.behance.net/SystemsForHumans',
}];

export const clients = {
    title: 'Startups powered by human design',
    items: [{
        src: '/images/clients/logo-ranksense.png',
        alt: 'Ranksense',
        density: [1, 2, 4],
    },{
        src: '/images/clients/logo-tahometer.png',
        alt: 'Tahometer',
        density: [1, 2, 4],
    },{
        src: '/images/clients/logo-logflare.png',
        alt: 'Logflare',
        density: [1, 2, 4],
    },{
        src: '/images/clients/logo-capraise.png',
        alt: 'CapRaise',
        density: [1, 2, 4],
    },{
        src: '/images/clients/logo-showhow.png',
        alt: 'ShowHow',
        density: [1, 2, 4],
    },{
        src: '/images/clients/logo-clarity.png',
        alt: 'Clarity',
        density: [1, 2, 4],
    },{
        src: '/images/clients/logo-30k.png',
        alt: '30K',
        density: [1, 2, 4],
    },{
        src: '/images/clients/logo-kimera.png',
        alt: 'Kimera Systems',
        density: [1, 2, 4],
    },{
        src: '/images/clients/logo-clarity.png',
        alt: 'Clarity',
        density: [1, 2, 4],
    },{
        src: '/images/clients/logo-ranksense.png',
        alt: 'Ranksense',
        density: [1, 2, 4],
    },{
        src: '/images/clients/logo-tahometer.png',
        alt: 'Tahometer',
        density: [1, 2, 4],
    },{
        src: '/images/clients/logo-logflare.png',
        alt: 'Logflare',
        density: [1, 2, 4],
    },{
        src: '/images/clients/logo-capraise.png',
        alt: 'CapRaise',
        density: [1, 2, 4],
    },{
        src: '/images/clients/logo-showhow.png',
        alt: 'ShowHow',
        density: [1, 2, 4],
    },{
        src: '/images/clients/logo-30k.png',
        alt: '30K',
        density: [1, 2, 4],
    },{
        src: '/images/clients/logo-kimera.png',
        alt: 'Kimera Systems',
        density: [1, 2, 4],
    }],
};

export const footer = {
    about: {
        title: 'Who are we?',
        content: <Paragraph>We’re a <Link url="/about/" title="Learn more about us">boutique agency</Link> blending technology and <Link url="/offer/" title="Check packages and services we offer">business needs</Link> to design complex web &amp; mobile <Link url="/portfolio/" title="See examples of Human Designed applications">applications with a human touch</Link>.</Paragraph>,
    },
    contact: {
        title: 'Get in touch',
        content: <Paragraph>Send us a <Link url="#/contact/">message</Link>, write an email: <Link url="mailto:hi@systemsforhumans.com">hi@systemsforhumans.com</Link> or simply call: +48 601 066 287 (Mon-Fri 10 am-6 pm CET).</Paragraph>,
    },
    navigation: {
        title: 'Explore',
    },
    copyright: {
        content: 'Registered office: Artur Polkowski IT Services, VAT EU: PL777-299-72-09, Brzozowa 10, 62095 Murowana Goslina, PL',
    },
};

export const cookie = {
    message: 'We use cookies to improve your experience and for marketing.',
    acceptButton: {
        title: 'Accept',
        hint: 'Accept & close',
    },
    moreButton: {
        title: 'Learn more',
        hint: 'Read our Cookie policy',
        url: '/privacy/',
    },
    closeButton: {
        hint: 'Accept & close',
    },
};