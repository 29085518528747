import React from "react";
import pt from "prop-types";
import { Box, Flex, FlexItem, Breakpoint, BreakpointContext, breakpointSwitch } from '@arturpol/style-guide/src/components';
import { appendHtmlClass, getAnimationDuration, getAnimationEasing } from '@arturpol/style-guide/src/js/util';
import { SliderNavBullet } from '../';
import './slider.scss';

class Slider extends React.Component{

    animationDuration = 'normal';
    dragIgnoreThreshold = 10;

    static contextType = BreakpointContext;

    constructor(props){

        super(props);

        this.state = {
            slide: (props.data.length > 0 ? props.data[0].id : null),
            isScrollable: false,
        };

        this.onTouchMove = this.onTouchMove.bind(this);
        this.ref = React.createRef();

    }

    getBreakpointValue(options){
        
        return breakpointSwitch(options, this.context);

    }

    moveSlide(delta){

        this.setState((state, props) => {
            
            let index = props.data.findIndex(i => i.id === state.slide);
        
            if(index < 0) index = 0;
            else {
                
                const requested = index + delta;
                if(requested >= 0 && requested < props.data.length) index = requested;
                else if(requested >= props.data.length) index = props.data.length - 1;

            }
            
            return {
                slide: props.data[index].id,
            };

        });

    }

    onSlideChange(id){
        
        this.setState({
            slide: id,
        });

    }

    onDragEnd(event, info){
        
        const offset = info.offset.x;
        if(Math.abs(offset) < this.dragIgnoreThreshold) return false;
        const delta = offset > 0 ? -1 : 1;
        this.moveSlide(delta);

    }

    onDragStart(event, info){
        
        const isScrollable = Math.abs(info.delta.y) < Math.abs(info.delta.x);

        this.setState({
            isScrollable,
        });

    }

    componentDidMount(){
        document.documentElement.addEventListener('touchmove', this.onTouchMove);
    }

    componentWillUnmount(){
        document.documentElement.removeEventListener('touchmove', this.onTouchMove);
    }

    onTouchMove(event){
        
        const contains = this.ref && this.ref.current && (this.ref.current === event.target || this.ref.current.contains(event.target));
        if(!this.state.isScrollable && contains) event.stopPropagation();
        
    }

    getSlidesAnimationProps(){
        
        const isAnimated = true;
        const index = this.props.data.findIndex(i => i.id === this.state.slide);
        
        const animate = {
            left: `${index * -100}%`,
        };

        const transition = {
            duration: getAnimationDuration(this.animationDuration),
            ease: getAnimationEasing('ease'),
        };

        const props = {
            isAnimated,
            animate,
            initial: false,
            transition,
            drag: 'x',
            dragConstraints: { left: 0, right: 0, top: 0, bottom: 0 },
            onDragStart: this.onDragStart.bind(this),
            onDragEnd: this.onDragEnd.bind(this),
            dragDirectionLock: true,
        };
        
        const result = this.getBreakpointValue({ 's': props, 'l': {}, });
        
        return result;

    }

    render(){
        
        let { data, className, children, ...rest } = this.props;
        const { slide } = this.state;
        const width = `${data.length * 100}%`;
        const animationProps = this.getSlidesAnimationProps();
        
        className = appendHtmlClass(className, 'slider');
        
        return (
            <>
                <Box className={className} mask="x" {...rest}>
                    <Flex as="ul" className="slides" alignItemsY="stretch" gapX={{'mDown': 'padding'}} width={{'mDown': width }} ref={this.ref} {...animationProps}>
                        {
                            data.map((i, index) => (
                                <FlexItem as="li" flex="1" borderRight={{'l': ['solid', 'gray', 2]}} paddingBottom={2} paddingLeft={{'mDown': 2}} paddingRight={{'mDown': 2}} key={i.id}>
                                    {children(i, index)}
                                </FlexItem>
                            ))
                        }
                    </Flex>
                </Box>
                <Breakpoint mDown={
                    <SliderNavBullet slides={data} current={slide} onSlideChange={this.onSlideChange.bind(this)} marginTop={2} />
                } />
            </>
        );
    
    };

}

Slider.propTypes = {
    data: pt.arrayOf(pt.shape({
        id: pt.any.isRequired,
    })),
    children: pt.func,
};

export default Slider;