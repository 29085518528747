import React from "react";
import pt from "prop-types";
import { Flex, FlexItem, Image } from '@arturpol/style-guide/src/components';
import { appendHtmlClass } from '@arturpol/style-guide/src/js/util';

const ProjectImage = ({ className, image, span, ...rest }) => {
    
    className = appendHtmlClass(className, 'project-image');

    return (
        <Flex className={className} gapX={{'m': 'margin'}} alignItemsX="center" {...rest}>
            <FlexItem as="figure" span={{'m': span}} marginBottom={{'s': 2, 'm': 3}}>
                <Image {...image} />
            </FlexItem>
        </Flex>
    );

}

ProjectImage.propTypes = {
	image: pt.object.isRequired,
};

ProjectImage.defaultProps = {
    span: 12,
};

export default ProjectImage;