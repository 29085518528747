import { TemplateLegal } from "../../../../src/templates";
import { Heading, Lead, Paragraph, List, ListItem, Link } from '@arturpol/style-guide/src/components';
import { Ruler, Email, Section } from "../../../../src/components";
import * as React from 'react';
export default {
  TemplateLegal,
  Heading,
  Lead,
  Paragraph,
  List,
  ListItem,
  Link,
  Ruler,
  Email,
  Section,
  React
};