import React from "react";
import pt from "prop-types";
import { Heading, Caption, Box } from '@arturpol/style-guide/src/components';
import { appendHtmlClass } from '@arturpol/style-guide/src/js/util';
import { Callout, Button } from '../../components';
import '../../images/test-drive.svg';
import './callout.scss';

const CalloutOffer = ({ className, data, ...rest }) => {

    className = appendHtmlClass(className, 'callout-offer');

	return (
		<Callout className={className} {...rest}>
            <Heading size={3} importance={5}>{data.title}</Heading>
            {data.content}
            <Box as="figure" className="price">
                <Heading as="div" size={4}>{data.price.value}</Heading>
                <Caption as="figcaption">{data.price.label}</Caption>
            </Box>
            <Box as="nav" padding={{'s': [1, 2, 2], 'm': [1, 0, 2]}}>
                <Button iconRight="long-arrow-alt-right" width={{ 'sOnly': 'stretch' }} url={data.cta.url} title={data.cta.hint}>{data.cta.label}</Button>
            </Box>
        </Callout>
	);
}

CalloutOffer.propTypes = {
	data: pt.object,
};

export default CalloutOffer;