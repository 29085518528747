import React from "react";
import pt from "prop-types";
import { Box, AspectRatio, Image, Heading } from '@arturpol/style-guide/src/components';
import { appendHtmlClass } from '@arturpol/style-guide/src/js/util';
import { HashtagList } from '../';
import './offeritem.scss';

const OfferItem = ({ className, data, ...rest }) => {
    
    className = appendHtmlClass(className, 'offer-item');

    return (
        <Box className={className} {...rest} border={{ 'm': ['solid', 'gray', 2] }} bg={['white']} padding={{ 'm': [5, 5, 2, 5] }} marginBottom={6}>
            <AspectRatio as="figure" className="offer-item-img" ratio="3x2">
                <Image {...data.img} />
            </AspectRatio>
            <Heading importance={3} size={2}>{data.title}</Heading>
            {data.content}
            <HashtagList data={data.tags} />
        </Box>
    );

}

OfferItem.propTypes = {
	data: pt.object,
};

export default OfferItem;