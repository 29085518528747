import React from "react";
import pt from "prop-types";
import { Box, Container } from '@arturpol/style-guide/src/components';
import { appendHtmlClass } from '@arturpol/style-guide/src/js/util';
import { Project } from '../../components';
import './portfoliosection.scss';

const PortfolioSection = ({ className, projects, pos, span, callout, children, ...rest }) => {
    
    className = appendHtmlClass(className, 'portfolio-projects');

    return projects.length > pos && (
        <>
            <Box as="section" className={className} {...rest}>
                {children}
                <Container>
                { 
                    projects.slice(pos, pos + span).map(i => (
                        <Project as="article" data={i} key={i.id} />
                    ))
                }
                </Container>
            </Box>
            {callout}
        </>
    );

}

PortfolioSection.propTypes = {
    pos: pt.number.isRequired,
    span: pt.number.isRequired,
    projects: pt.arrayOf(pt.object).isRequired,
    callout: pt.element,
};

export default PortfolioSection;