import React, { useCallback, useRef, useState, useMemo, useEffect } from 'react';
import pt from "prop-types";
import { Grid, GridItem, Heading, Form, Field, Label, Input, Select, Checkbox, 
    Textarea, Flex, FlexItem, Small, Box, Paragraph, Lead, Icon, Element, Image } from '@arturpol/style-guide/src/components';
import { appendHtmlClass, getAnimationDuration, getAnimationEasing, getSpaceValue } from '@arturpol/style-guide/src/js/util';
import { RulerForSmallOnly, Button, SecondaryButton, Link } from '../';
import { encode } from '../../util';
import './contact.scss';
import { text } from '../../data/global';

const FORM_STATE_DEFAULT = 'default';
const FORM_STATE_SUCCESS = 'success';

const Contact = ({params, className, children, meta, form, details, close, drawerRef, ...rest}) => {

    const newClassName = appendHtmlClass(className, 'client-contact');
    const defaultValues = {...form.defaultValues, ...params};
    const data = form;
    const noteRef = useRef(null);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [state, setState] = useState(FORM_STATE_DEFAULT);
    const [isTouchDevice, setIsTouchDevice] = useState(true);
    
    useEffect(() => {
        
        setIsTouchDevice('ontouchstart' in window || navigator.msMaxTouchPoints);

    }, [setIsTouchDevice]);

    const scrollTop = useCallback(() => {

        if(drawerRef && drawerRef.current){
            
            drawerRef.current.scroll({
                top: 0,
                behavior: 'smooth',
            });

        }

    }, [drawerRef]);

    const onSuccess = useCallback(() => {
        
        setState(FORM_STATE_SUCCESS);
        setHasError(false);
        setIsLoading(false);

    }, [setState, setHasError, setIsLoading]);

    const onFailure = useCallback((error) => {

        setState(FORM_STATE_DEFAULT);
        setHasError(true);
        setIsLoading(false);
        scrollTop();

    }, [setState, setHasError, setIsLoading, scrollTop]);

    const send = useCallback(params => {

        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({ 'form-name': data.name, ...params }),
        })
            .then((response) => {
                
                if(response.status === 200) onSuccess();
                else onFailure(response);

            })
            .catch(onFailure);

    }, [onFailure, onSuccess, data.name]);

    const onSubmit = useCallback(({form, fields}, event) => {
        
        setIsSubmitted(true);
        event.preventDefault();

        if(!form.isValid) return false;
        let result = Object.keys(fields).map(name => ({ [name]: fields[name].value, }));
        result = Object.assign.apply(this, result);
        result = Object.assign({}, defaultValues, result);

        setIsLoading(true);
        send(result);

    }, [setIsSubmitted, defaultValues, send]);

    const getFieldInput = ({component, type, options, content, placeholder, ...rest}) => {

        switch(component){

            case 'Select':
                return <Select placeholder={placeholder} options={options} kind="outline" {...rest} />;
            
            case 'Textarea':
                return <Textarea type={type} placeholder={placeholder} rows={10} kind="outline" {...rest} />;
            
            case 'Checkbox':
                return <Checkbox {...rest}>{content}</Checkbox>;
            
            default:
                return <Input type={type} placeholder={placeholder} kind="outline" {...rest} />;

        }

    };

    const getField = ({label, id, caption, ...field}) => {
        return <Field label={label} caption={caption} id={id} key={id} isDisabled={isLoading}>{getFieldInput(field)}</Field>;
    };

    const onAnimationEnd = (hasNote) => {
        
        if(!hasNote) noteRef.current = null;

    };

    const getSubjectNote = (field, state) => {
        
        const option = state.value ? field.options.find(i => i.id === state.value) : null;
        const hasNote = option && option.hasOwnProperty('note') && typeof option.note === 'object';
        if(hasNote) noteRef.current = option.note;
        let jsx = null;

        if(noteRef.current){
            
            const { title, content } = noteRef.current;
            const variants = { hidden: { opacity: 0 }, visible: { opacity: 1}};
            
            jsx = (
                <Box isAnimated variants={variants} bg={['primary', 4]} padding={[3, 3, 1]} marginBottom={4}>
                    <Heading size={1} importance={6} color={['primary', -4]}>{title}</Heading>
                    <Paragraph color={['primary', -4]}>{content.join(' ')}</Paragraph>
                    {false && content.map((i, index) => <Small key={index.toString()}>{i}</Small>)}
                </Box>
            )

        }

        const hidden = {
            height: 0,
        };

        const visible = {
            height: 'auto',
        };

        const transition = {
            duration: getAnimationDuration('slow'),
            ease: getAnimationEasing('easeSoft'),
        };

        const props = {
            isAnimated: true,
            variants: {hidden, visible},
            animate: (hasNote ? 'visible' : 'hidden'),
            onAnimationEnd: onAnimationEnd.bind(this, hasNote),
            transition: transition,
            initial: false,
            mask: 'xy',
        };
        
        return { props, content: jsx };

    };

    const formErrorMessageProps = useMemo(() => {

        const hidden = {
            opacity: 0,
            y: getSpaceValue(2),
        };

        const visible = {
            opacity: 1,
            y: 0,
        };

        return {
            isAnimated: true,
            variants: { hidden, visible },
            initial: 'hidden',
            transition: {
                ease: getAnimationEasing('easeSoft'),
                duration: getAnimationDuration('slow'),
            },
        };

    }, []);

    const getHeading = useCallback(title => (
        <Box as="hgroup" paddingBottom={2}>
            <Heading size={2} importance={2} isAlternative>{meta.title}</Heading>
            <Heading className="client-heading" size={5} importance={5}>{title}</Heading>
        </Box>
    ), [meta.title]);

    const stateAnimationProps = useMemo(() => {

        return {
            isAnimated: true,
            variants: {
                hidden: {
                    opacity: 0,
                    transitionEnd: {
                        position: 'absolute',
                        zIndex: -1,
                    },
                    transition: {
                        duration: getAnimationDuration('slower'),
                        ease: getAnimationEasing('ease'),
                    },
                },
                visible: {
                    opacity: 1,
                    position: 'static',
                    transition: {
                        duration: getAnimationDuration('slower'),
                        ease: getAnimationEasing('ease'),
                        delay: getAnimationDuration('slower')
                    },
                },
            },
        };

    }, []);

    const onCloseClick = useCallback((event) => {
        
        close();

    }, [close]);

    const onResetClick = useCallback((reset, event) => {
        
        reset();
        setState(FORM_STATE_DEFAULT);

    }, [setState]);

    const alertBoxAnimationProps = useMemo(() => {

        const show = {
            opacity: 1,
            y: 0,
            position: 'static',
            zIndex: 'auto',
        };

        const hide = {
            opacity: 0,
            y: getSpaceValue(-6),
            transitionEnd: {
                position: 'absolute',
                zIndex: -1,
            },
        };

        const transition = {
            duration: getAnimationDuration('slower'),
            ease: getAnimationEasing('ease'),
            delay: getAnimationDuration('slow'),
        };

        return {
            isAnimated: true,
            variants: {show, hide},
            transition: transition,
        };

    }, []);

    const onAlertCloseClick = useCallback((event) => {
        
        event.preventDefault();
        setHasError(false);

    }, [setHasError]);

    return (
        <Form name={data.name} className={newClassName} padding={{'s': [4, 0], 'm': [4, 2, 2]}} 
            defaultValues={defaultValues} onSubmit={onSubmit}>
            {
                ({form, fields, reset}) => {
                    const {props, content} = getSubjectNote(data.fields.subject, fields.subject);
                    return (
                        <>
                            <Grid columns={{'s': 1, 'm': 2}} gapY={0} gapX={6} {...stateAnimationProps} initial="visible" animate={state === FORM_STATE_DEFAULT ? 'visible' : 'hidden'}>
                                <GridItem spanX={{'m': 2}}>
                                    {getHeading(data.title)}
                                </GridItem>
                                <GridItem spanX={{'m': 2}} {...alertBoxAnimationProps} animate={hasError ? 'show' : 'hide'} initial="hide">
                                    <Box className="alert-box" bg={['error']} padding={[2, 6, 2, 3]} shadow={3} marginBottom={4}>
                                        <Paragraph color={['white']}>
                                            <Icon name="exclamation" display="iblock" /> <Element as="strong">{data.failure.title}</Element> {data.failure.content}
                                        </Paragraph>
                                        <Link type="raw" title={text.close} onClick={onAlertCloseClick}>
                                            <Icon className="alert-box-close" name="times" color={['white']} />
                                        </Link>
                                    </Box>
                                </GridItem>
                                <GridItem>
                                    {getField(data.fields.subject)}
                                </GridItem>
                                <GridItem></GridItem>
                                <GridItem spanX={{'m': 2}} marginTop={-3} {...props}>{content}</GridItem>
                                <GridItem>
                                    {getField({...data.fields.name, autoFocus: !isTouchDevice, tabIndex: 8, })}
                                </GridItem>
                                <GridItem spanY={{'m': 3}} posY={{'sOnly': 8}}>
                                    {getField({...data.fields.message, tabIndex: 32, })}
                                </GridItem>
                                <GridItem>
                                    {getField({...data.fields.company, tabIndex: 16, })}
                                </GridItem>
                                <GridItem>
                                    {getField({...data.fields.email, tabIndex: 24, })}
                                </GridItem>
                                <GridItem>
                                    {getField({...data.fields.terms, tabIndex: 40, })}
                                    <Flex wrap="wrap">
                                        <FlexItem span={{'s': 12, 'm': 'shrink'}}>
                                            <Button type="submit" iconRight="long-arrow-alt-right" tabIndex="48" isLoading={isLoading}>{data.submit.label}</Button>
                                        </FlexItem>
                                        <FlexItem span={{'s': 12, 'm': 'auto'}} paddingLeft={{'m': 2}} paddingTop={{'m': 1}}>
                                            <Label color="error" animate={isSubmitted && !form.isValid ? 'visible' : 'hidden'} {...formErrorMessageProps}>{data.submit.invalid}</Label>
                                        </FlexItem>
                                    </Flex>
                                </GridItem>
                                <GridItem>
                                    <RulerForSmallOnly marginLeft={{'sOnly': -4}} />
                                    {details}
                                </GridItem>
                            </Grid>
                            <Box {...stateAnimationProps} initial="hidden" animate={state === FORM_STATE_SUCCESS ? 'visible' : 'hidden'}>
                                {getHeading(data.success.title)}
                                <Lead>{data.success.content}</Lead>
                                <Box as="figure" className="sent-success">
                                    <Image {...data.success.img} />
                                </Box>
                                <Heading size={1} importance={1}>{data.success.actions.title}</Heading>
                                <Flex wrap="wrap">
                                    <FlexItem span={{'sOnly': 12}}>
                                        <Button iconLeft="long-arrow-alt-left" title={data.success.actions.closeButton.hint} onClick={onCloseClick} width={{'sOnly': 'stretch'}}>{data.success.actions.closeButton.label}</Button>
                                    </FlexItem>
                                    <FlexItem span={{'sOnly': 12}} paddingLeft={{'m': 2}}>
                                        <SecondaryButton title={data.success.actions.resetButton.hint} onClick={onResetClick.bind(this, reset)} width={{'sOnly': 'stretch'}}>{data.success.actions.resetButton.label}</SecondaryButton>
                                    </FlexItem>
                                </Flex>
                            </Box>
                        </>
                    );
                }
            }
        </Form>
    );

};

Contact.propTypes = {
    params: pt.object,
    form: pt.object.isRequired,
};

export default Contact;