import React from "react";
import pt from "prop-types";

import { Element } from '@arturpol/style-guide/src/components';
import { appendHtmlClass } from '@arturpol/style-guide/src/js/util';

import './hashtaglist.scss';

const HashtagList = ({ className, data, ...rest }) => {
    
    className = appendHtmlClass(className, 'hashtag-list');

    return (
        <Element as="ul" className={className} {...rest}>
            { data.map(i => {
                const tag = `#${i.toLowerCase()}`;
                return <Element as="li" key={tag}>{tag}</Element>
            })}
        </Element>
    );

}

HashtagList.propTypes = {
	data: pt.arrayOf(pt.string),
};

export default HashtagList;