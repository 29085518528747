import React, { useEffect, useState, useMemo, useCallback } from "react";
import pt from "prop-types";

import { Heading, Flex, FlexItem, Container, Box, Small, Element, Image } from '@arturpol/style-guide/src/components';
import { appendHtmlClass, getAnimationDuration, getAnimationEasing } from '@arturpol/style-guide/src/js/util';
import { Ruler, Navigation, NavigationSocial } from '../';

import './footer.scss';

import * as layout from "../../data/layout";

const pageUpdateIntervalMs = getAnimationDuration('slowest') * 3000;
const itemsPerPage = 8;
const maxOpacity = 0.33;

const getClientsPages = (itemsPerPage) => {

    const items = layout.clients.items;
    let pages = [];
    let page = [];
    
    for(let i = 0 ; i < items.length ; i++){
        
        page.push(items[i]);
        
        if(page.length >= itemsPerPage){
            
            pages.push(page);
            page = [];

        }

    }

    if(page.length > 0){

        if(pages.length > 0){

            const lastPage = pages[pages.length - 1];
            const remainingItems = itemsPerPage - page.length;
            const itemsToAdd = lastPage.slice(-1 * remainingItems);
            page = itemsToAdd.concat(page);

        }

        pages.push(page);
    }

    return pages;

};

const pages = getClientsPages(itemsPerPage);

const Footer = ({ title, className, ...rest }) => {

    className = appendHtmlClass(className, 'page-footer');
    const [current, setCurrent] = useState(pages.length > 0 ? 0 : null);
    const [isEnabled, setIsEnabled] = useState(true);
    
    useEffect(() => {

        const updatePage = () => {
            if(isEnabled) setCurrent(current + 1 < pages.length ? current + 1 : 0);
        };
        
        const intervalHandler = setInterval(updatePage, pageUpdateIntervalMs);
        return () => { clearTimeout(intervalHandler); };

    }, [setCurrent, current, isEnabled]);

    const currentPage = current !== null ? pages[current] : null;

    const pageAnimationProps = useMemo(() => {
        
        return {
            isAnimated: true,
            initial: 'hidden',
            variants: {
                hidden: {
                    opacity: 0,
                    transitionEnd: {
                        position: 'absolute',  
                    },
                },
                visible: {
                    opacity: maxOpacity,
                    transitionEnd: {
                        position: 'static',
                    },
                },
            },
            transition: {
                ease: getAnimationEasing('linear'),
                duration: getAnimationDuration('slowest'),
            },
        };

    }, []);

    const onMouseEnter = useCallback((e) => {
        setIsEnabled(false);
    }, [setIsEnabled]);

    const onMouseLeave = useCallback((e) => {
        setIsEnabled(true);
    }, [setIsEnabled]);

	return (
        <>
            {currentPage !== null && 
                <Box as="aside" className="clients" padding={[4, 0, 3]} bg={['gray', 4]} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                    <Container>
                        <Heading className="clients-title" importance={5} size={1} color={['gray', 0]} align="center">{layout.clients.title}</Heading>
                        <Box as="div" className="clients-wrapper" marginTop={{'s': 2, 'm': 0}}>
                            {pages.map((page, index) =>(
                                <Flex as="ul" gapX="margin" wrap={{'s': 'wrap', 'm': 'nowrap'}} alignItemsX={{'s': 'space-around', 'm': 'space-between'}} 
                                    width="100%" animate={current === index ? 'visible' : 'hidden'} {...pageAnimationProps} key={index}>
                                    {page.map((item, index) => (
                                        <FlexItem as="li" marginBottom={1} key={index}>
                                            <Image {...item} />
                                        </FlexItem>
                                    ))}
                                </Flex>
                            ))}
                        </Box>
                    </Container>
                </Box>
            }
            <Box as="footer" className={className} paddingTop={{ 's': 8, 'm': 10 }} paddingBottom={8} bg={['gray', 3]} {...rest} >
                <Container>
                    <Flex gapX="margin" wrap="wrap">
                        {[
                            layout.footer.about, 
                            layout.footer.contact
                        ].map((i, index) => (
                            <FlexItem as="aside" span={{'m': 6, 'l': 3}} marginBottom={{ 's': 4, 'l': 2 }} key={i.title.toLowerCase()} order={{'s': index + 2, 'l': index}}>
                                <Heading size={1} importance={6}>{i.title}</Heading>
                                {i.content}
                            </FlexItem>
                        ))}
                        <FlexItem as="nav" span={{'m': 8, 'l': 4}} marginBottom={{ 's': 4, 'm': 6, 'l': 2 }} order={{'s': 0, 'l': 2}}>
                            <Heading size={1} importance={6}>{layout.footer.navigation.title}</Heading>
                            <Navigation className="wrap" wrap="wrap" alignItemsX="flex-start" includeLegal />
                        </FlexItem>
                        <FlexItem as="nav" span={{'m': 4, 'l': 2}} paddingTop={{ 'm': 4 }} marginBottom={{ 's': 4, 'm': 6, 'l': 2 }} order={{'s': 1, 'l': 3}}>
                            <NavigationSocial />
                        </FlexItem>
                    </Flex>
                    <Ruler marginTop={{ 's': 2, 'm': 0 }} />
                        <Small color={['gray', 0]}>
                            © {(new Date()).getFullYear()} 
                            {` ${title}`}
                            {layout.footer.copyright.content && <Element as="div">{layout.footer.copyright.content}</Element>}
                        </Small>
                </Container>
            </Box>
        </>
	);
}

Footer.propTypes = {
	title: pt.string.isRequired,
};

export default Footer;