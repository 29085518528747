import { TemplateClient } from "../../../../src/templates";
import { Contact } from "../../../../src/components";
import { Heading, Paragraph, Link } from '@arturpol/style-guide/src/components';
import formData from "../../../../src/data/contact";
import * as React from 'react';
export default {
  TemplateClient,
  Contact,
  Heading,
  Paragraph,
  Link,
  formData,
  React
};