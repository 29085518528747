import React from "react";
import pt from "prop-types";
import { Image, Heading, Paragraph, List, ListItem, Flex, FlexItem, Element, Breakpoint, Label, ButtonBar, Box, Small, BreakpointContext, breakpointSwitch } from '@arturpol/style-guide/src/components';
import { appendHtmlClass } from '@arturpol/style-guide/src/js/util';
import { Button, SecondaryButton, Link, Ruler, OfferFigureWireframe, OfferFigureDesign, OfferFigureBrandBasic, OfferFigureBrandPro } from '../';
import { AnimationDuration } from '../../util';
import './offerpackage.scss';

class OfferPackage extends React.Component{

    static contextType = BreakpointContext;

    constructor(props){
        
        super(props);

        const data = props.data;
        let version = Array.isArray(data.versions) && data.versions.length > 0 ? data.versions[0].id : null;

        this.state = {
            version,
            isMouseOver: false,
        };

    }

    getVersion(){

        if(!this.state.version) return null;
        const matches = this.props.data.versions.filter(i => i.id === this.state.version);
        return matches.length > 0 ? matches[0] : null;

    }

    onVersionClick(version, e){

        if(version){

            this.setState({
                version: version.id,
            });

        }

    }

    getCtaUrl(url){

        const item = this.props.data.id;
        const version = this.state.version;
        return url.replace('{package}', item).replace('{version}', version);

    }

    onHoverStart(){

        this.setState({
            isMouseOver: true,
        });

    }

    onHoverEnd(){

        this.setState({
            isMouseOver: false,
        });

    }
    
    render(){
        
        let { className, data, text, ...rest } = this.props;

        className = appendHtmlClass(className, 'offer-package');
        
        const animatePermanently = breakpointSwitch({
            'mDown': true, 
            'l': false
        }, this.context);

        const version = this.getVersion();
        if(!version) return null;

        let figure = null;
        const animate = this.state.isMouseOver || animatePermanently;

        if(version.animation){
            
            switch(version.animationId){
                case 'wireframe': 
                    figure = <AnimationDuration isAnimating={animate} animation={OfferFigureWireframe} />;
                    break;
                case 'design': 
                    figure = <AnimationDuration isAnimating={animate} animation={OfferFigureDesign} />;
                    break;
                case 'brand-basic': 
                    figure = <AnimationDuration isAnimating={animate} animation={OfferFigureBrandBasic} />;
                    break;
                case 'brand-pro': 
                    figure = <AnimationDuration isAnimating={animate} animation={OfferFigureBrandPro} />;
                    break;
                default: 
            }

        }else{
            figure = <Image src={version.img.src} />;
        }

        let img = (
            <Element as="figure">{figure}</Element>
        );

        return ( 
            <Flex as="article" className={className} padding={{'s': 3, 'l': 2}} margin={[5, 0]} wrap="wrap" bg={['white']} shadow={4} 
                isAnimated onHoverStart={this.onHoverStart.bind(this)} onHoverEnd={this.onHoverEnd.bind(this)} {...rest}>
                <Breakpoint l={<FlexItem span={3}>{img}</FlexItem>} />
                <FlexItem span={{'s': 12, 'l': 9}} paddingLeft={{'l': 4}} paddingTop={{'mDown': 1}}>
                    <Flex className="package-header" gapX={{'l': 'margin'}} wrap="wrap" marginBottom={{'l': 2}}>
                        <FlexItem span={{'s': 12, 'l': 'auto'}} paddingTop={{'l': 1}}>
                            <Heading className="package-title" importance={3} size={{'s': 2, 'l': 4}} isAlternative>{data.title}</Heading>
                        </FlexItem>
                        { data.versions.length > 1 && (
                            <>
                                <FlexItem span={{'s': 12, 'l': 'shrink'}}>
                                    <Flex gapX={{'l': 'margin'}} wrap={{'mDown': 'wrap'}} alignItemsY="center">
                                        <FlexItem span={{'s': 12, 'l': 'shrink'}}>
                                            <Label className="nowrap">{text.versionLabel}</Label>
                                        </FlexItem>
                                        <FlexItem span={{'s': 12, 'l': 'auto'}}>
                                            <ButtonBar kind="text" color="gray" isStretched>
                                                {
                                                    data.versions.map(i => (
                                                        <SecondaryButton isSelected={i.id === version.id} onClick={this.onVersionClick.bind(this, i)} key={i.id}>{i.name}</SecondaryButton>
                                                    ))
                                                }
                                            </ButtonBar>
                                        </FlexItem>
                                    </Flex>
                                </FlexItem>
                                <Breakpoint l={<Ruler className="version-ruler" margin={0} />} />
                            </>
                        )}
                    </Flex>
                    <Breakpoint mDown={<Box margin={[0, -3, 4]}>{img}</Box>} />
                    <Flex gapX={{'l': 'margin'}} wrap="wrap">
                        <FlexItem span={{'s': 12, 'l': 4}} marginBottom={2}>
                            <Heading as="div" size={2} isAlternative>{text.featuresTitle}</Heading>
                            <List className="included" type="unordered">
                                {
                                    version.features.map((i, index) => (
                                        <ListItem key={index}>{i}</ListItem>
                                    ))
                                }
                            </List>
                        </FlexItem>
                        <FlexItem span={{'s': 12, 'l': 4}} marginBottom={2}>
                            <Heading as="div" size={2} isAlternative>{text.descriptionTitle}</Heading>
                            <Paragraph>{version.description}</Paragraph>
                        </FlexItem>
                        <FlexItem span={{'s': 12, 'l': 4}} paddingTop={{'l': 3}} align={{'s': 'center', 'l': 'right'}}>
                            <Flex gapX="margin" borderTop={{'mDown': ['solid', 'gray', 3]}} borderBottom={{'mDown': ['solid', 'gray', 3]}} padding={{'mDown': [3, 3, 1]}} margin={{'s': [0, -3], 'l': [0, 0, 2]}}>
                                <FlexItem span={6}>
                                    <Heading as="div" size={1} isAlternative>{version.isFixedPrice ? text.fixedDurationTitle : text.hourlyDurationTitle}</Heading>
                                    <Heading as="div" className="price" size={4} isAlternative>{version.duration}</Heading>
                                </FlexItem>
                                <FlexItem span={6}>
                                    <Heading as="div" size={1} isAlternative>{version.isFixedPrice ? text.fixedPriceTitle : text.hourlyPriceTitle}</Heading>
                                    <Heading as="div" className="price" size={4}>{version.price}</Heading>
                                </FlexItem>
                            </Flex>
                            <Box margin={{'mDown': [4, 4, 1]}}>
                                <Button iconRight="long-arrow-alt-right" width={{'s': 'stretch', 'l': 'normal'}} title={text.cta.hint} url={this.getCtaUrl(text.cta.url)}>{text.cta.label}</Button>
                            </Box>
                            { data.link && 
                                <Box marginTop={{'s': 4, 'l': 0}}>
                                    <Paragraph>
                                        <Link url={data.link.url} title={data.link.hint}>{data.link.label}</Link>
                                    </Paragraph>
                                </Box>
                            }
                            { version.footer && <Small color={['gray']}>{version.footer}</Small> }
                        </FlexItem>
                    </Flex>
                </FlexItem>
            </Flex>
        );

    }

}

OfferPackage.propTypes = {
    data: pt.object.isRequired,
    text: pt.object.isRequired,
};

export default OfferPackage;