import React from "react";
import pt from "prop-types";

import { Heading, Flex, FlexItem, Element, Breakpoint, Container, Box } from '@arturpol/style-guide/src/components';
import { appendHtmlClass } from '@arturpol/style-guide/src/js/util';
import { Navigation, NavigationSocial, Link, Logo } from '../';

import './header.scss';

const Header = ({ title, className, ...rest }) => {

	const sideColumnWidth = 32;

	className = appendHtmlClass(className, 'page-header');

	return (
		<Element as="header" className={className} {...rest} >
			<Container>
				<Flex height={{ s: 10, m: 8 }} alignItemsX="space-between" alignItemsY="center">
					<Box width={sideColumnWidth}>
						<Link className="header-logo" type="raw" title={title} url="/">
							<Logo title={title} fitToHeight isAnimatedOnHover />
							<Heading className="logo-name" importance={1} size={2}>{title}</Heading>
						</Link>
					</Box>
					<Breakpoint l={
						<FlexItem as="nav" span="auto" margin={[0, 3]}>
							<Navigation />
						</FlexItem>
					} />
					<Breakpoint m={
						<Box as="nav" width={sideColumnWidth}>
							<NavigationSocial />
						</Box>
					} />
				</Flex>
			</Container>
		</Element>
	);
}

Header.propTypes = {
	title: pt.string.isRequired,
};

export default Header;