import React, { useEffect } from "react";
import pt from 'prop-types';
import { motion } from 'framer-motion';
import { useTotalDuration, useAnimationProp } from '../../util';
import './offerfigure.scss';
import slide1Image from '../../images/offer-brand-basic-1.jpg';
import slide2Image from '../../images/offer-brand-basic-2.jpg';
import slide3Image from '../../images/offer-brand-basic-3.jpg';
import slide4Image from '../../images/offer-brand-basic-4.jpg';
import slide5Image from '../../images/offer-brand-basic-5.jpg';
import slide6Image from '../../images/offer-brand-basic-6.jpg';
import slide7Image from '../../images/offer-brand-basic-7.jpg';

const OfferFigureBrandBasic = ({ duration }) => {

    const delay = 0.15;

    let slidesTransition = [
        delay, 1.5,
    ];
    slidesTransition.push(
        slidesTransition[0] + slidesTransition[1] + delay, 1,
    );
    slidesTransition.push(
        slidesTransition[2] + slidesTransition[3] + delay, 1.5,
    );
    slidesTransition.push(
        slidesTransition[4] + slidesTransition[5] + delay, 1.5,
    );
    slidesTransition.push(
        slidesTransition[6] + slidesTransition[7] + delay, 1,
    );
    slidesTransition.push(
        slidesTransition[8] + slidesTransition[9] + delay, 1.5,
    );
    slidesTransition.push(
        slidesTransition[10] + slidesTransition[11] + delay, 1.5,
    );
    slidesTransition.push(
        slidesTransition[12] + slidesTransition[13] + delay, 1,
    );
    slidesTransition.push(
        slidesTransition[14] + slidesTransition[15] + delay, 1.5,
    );
    slidesTransition.push(
        slidesTransition[16] + slidesTransition[17] + delay, 1.5,
    );

    const totalDuration = useTotalDuration([
        slidesTransition,
    ]);

    useEffect(() => {

        const unsubscribe = duration.onChange((elapsed) => {
            
            if(elapsed >= totalDuration) duration.set(totalDuration - elapsed);

        });

        return () => unsubscribe();

    });

    const slidesStyle = {
        translateX: useAnimationProp(duration, slidesTransition, 
            [64, 1100, 1100, 1240, 1240, -1750, -1750, -2900, -2900, -3200, -3200, -4300, -4300, -5200, -5200, -5700, -5700, -5160, -5160, -7500], 
            ['ease', 'linear', 'ease', 'linear', 'ease', 'linear', 'ease', 'linear', 'ease', 'linear', 'ease', 'linear', 'ease', 'linear', 'ease', 'linear', 'ease', 'linear', 'ease']),
        translateY: useAnimationProp(duration, slidesTransition, 
            [139, 148, 148, 148, 148, 139, 139, 139, 139, 148, 148, 139, 139, 139, 139, 139, 139, 139, 139, 139],
            ['easeIn', 'linear', 'linear', 'linear', 'easeOut', 'linear', 'linear', 'linear', 'easeIn', 'linear', 'easeOut', 'linear', 'linear', 'linear', 'linear', 'linear', 'easeOut', 'linear', 'linear']),
        scale: useAnimationProp(duration, slidesTransition, 
            [1, 1.5, 1.5, 1.85, 1.85, 1, 1, 1, 1, 1.5, 1.5, 1, 1, 1, 1, 1, 1, 0.5, 0.5, 1], 
            ['ease', 'linear', 'ease', 'linear', 'ease', 'linear', 'linear', 'linear', 'ease', 'linear', 'ease', 'linear', 'linear', 'linear', 'linear', 'linear', 'ease', 'linear', 'ease']),
    };

    return (
        <svg viewBox="0 0 640 640" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <title>Human Brand Design - Essential</title>
            
            <defs>
                <rect id="path-13" x="24" y="24" width="978" height="314"></rect>
                <filter x="-10.3%" y="-29.0%" width="120.7%" height="164.3%" filterUnits="objectBoundingBox" id="filter-14">
                    <feOffset dx="0" dy="10" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                    <feGaussianBlur stdDeviation="32" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.300890516 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                </filter>
                <rect id="path-4" x="24" y="24" width="464" height="314"></rect>
                <filter x="-21.8%" y="-29.0%" width="143.5%" height="164.3%" filterUnits="objectBoundingBox" id="filter-5">
                    <feOffset dx="0" dy="10" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                    <feGaussianBlur stdDeviation="32" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.300890516 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                </filter>
            </defs>
            <g id="brand1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect fill="#FCDE70" x="0" y="0" width="8204" height="640"></rect>
                <motion.g id="slides" style={slidesStyle}>
                    <g id="slide-1-repeat" transform="translate(7564.000000, 0.000000)">
                        <g id="shadow" fill="black" fillOpacity="1">
                            <use filter="url(#filter-5)" xlinkHref="#path-4"></use>
                        </g>
                        <image id="img-1" x="0" y="0" width="512" height="362.167712" href={slide1Image} />
                    </g>
                    <g id="slide-7" transform="translate(6410.000000, 0.000000)">
                        <g id="shadow" fill="black" fillOpacity="1">
                            <use filter="url(#filter-14)" xlinkHref="#path-13"></use>
                        </g>
                        <image id="img-7" x="0" y="0" width="1025.71265" height="362.67" href={slide7Image} />
                    </g>
                    <g id="slide-6" transform="translate(5256.000000, 0.000000)">
                        <g id="shadow" fill="black" fillOpacity="1">
                            <use filter="url(#filter-14)" xlinkHref="#path-13"></use>
                        </g>
                        <image id="img-6" x="0" y="0" width="1025.71265" height="362.67" href={slide6Image} />
                    </g>
                    <g id="slide-5" transform="translate(4102.000000, 0.000000)">
                        <g id="shadow" fill="black" fillOpacity="1">
                            <use filter="url(#filter-14)" xlinkHref="#path-13"></use>
                        </g>
                        <image id="img-5" x="0" y="0" width="1025.71265" height="362.67" href={slide5Image} />
                    </g>
                    <g id="slide-4" transform="translate(2948.000000, 0.000000)">
                        <g id="shadow" fill="black" fillOpacity="1">
                            <use filter="url(#filter-14)" xlinkHref="#path-13"></use>
                        </g>
                        <image id="img-4" x="0" y="0" width="1025.71265" height="362.67" href={slide4Image} />
                    </g>
                    <g id="slide-3" transform="translate(1794.000000, 0.000000)">
                        <g id="shadow" fill="black" fillOpacity="1">
                            <use filter="url(#filter-14)" xlinkHref="#path-13"></use>
                        </g>
                        <image id="img-3" x="0" y="0" width="1025.71265" height="362.67" href={slide3Image} />
                    </g>
                    <g id="slide-2" transform="translate(640.000000, 0.000000)">
                        <g id="shadow" fill="black" fillOpacity="1">
                            <use filter="url(#filter-14)" xlinkHref="#path-13"></use>
                        </g>
                        <image id="img-2" x="0" y="0" width="1025.71265" height="362.67" href={slide2Image} />
                    </g>
                    <g id="slide-1">
                        <g id="shadow" fill="black" fillOpacity="1">
                            <use filter="url(#filter-5)" xlinkHref="#path-4"></use>
                        </g>
                        <image id="img-1" x="0" y="0" width="512" height="362.167712" href={slide1Image} />
                    </g>
                </motion.g>
            </g>
        </svg>
    );

};

OfferFigureBrandBasic.propTypes = {
    animate: pt.bool,
};

OfferFigureBrandBasic.defaultProps = {
    animate: false,
};

export default OfferFigureBrandBasic;