import React from "react";
import { Link, Paragraph, Element } from '@arturpol/style-guide/src/components';

export const text = {
    more: 'More',
    projectFiguresHeading: 'Impact & value',
    projectQuoteHeading: 'Was it worth it?',
    projectBackButton: 'Back to the list',
    slideChangeLinkHint: 'Change slide',
    slideNextLinkHint: 'Next slide',
    slidePrevLinkHint: 'Previous slide',
    close: 'Close',
    projectProblemHeading: 'Problem',
    projectSolutionHeading: 'Solution',
};

export const callout = {
    features: {
        title: 'Take the first step',
        cta: {
            label: 'Schedule a free workshop',
            url: '#/contact/'
        },
        features: [{
            text: 'Product ideation',
            isPositive: true,
        },{
            text: '45 minutes of value',
            isPositive: true,
        },{
            text: 'No strings attached',
            isPositive: false,
        },{
            text: 'No sales talk',
            isPositive: false,
        }],
    },
    offer: {
        title: 'Ready for a test drive?',
        content: <Paragraph>Order a design blueprint for your application.</Paragraph>,
        cta: {
            label: 'Check out this offer',
            url: '/offer/',
        },
        price: {
            value: '$2,499',
            label: 'Fixed-price',
        },
    },
    about: {
        title: 'Not convinced yet?',
        content: <Paragraph>Learn the secret sauce of our <Element as="strong">human design</Element> workflow</Paragraph>,
        cta: {
            label: 'Learn more about us',
            url: '/about/',
        },
    },
};

export const quotes = [{
    id: 'ranksense',
    content: 'Artur provided us with amazingly detailed mockups and prototypes with very little input and guidance. He is very organized and methodical. I really enjoyed our collaboration and will definitely continue to work in the near future.',
    name: 'Hamlet Batista',
    title: <>CEO at <Link url="https://www.ranksense.com/" shouldOpenNewTab>Ranksense Inc.</Link></>,
    img: {
        src: '/images/ranksense/hamlet_batista_256.jpg',
    },
},{
    id: 'showhow-1',
    content: 'Artur provided a highly collaborative and efficient style of work which made it feel like he was part of our team rather than an external contractor. His work was polished and professional and he was very open to feedback. He assisted us to polish a prototype into a working saleable cloud service. I definitely recommend him for your UX and front end design requirements.',
    name: 'Jacinta Penn',
    title: <>Consultant on Customer Experience at <Link url="https://showhow.co/" shouldOpenNewTab>ShowHow</Link></>,
    img: {
        src: '/images/showhow/jacinta_penn_256.jpg',
    },
},{
    id: 'showhow-2',
    content: 'Artur worked to develop our user experience and redefine the UI for our VR learning platform. He brought a great understanding of user behaviour, innovative ideas for making complex processes simple and a great eye for design for a pretty and elegant interface. Artur is very easy to work with, a great communicator and delivers a reliable and professional service. His work was always on time and within budget, and he optimised the design process to ease issues caused by overspend elsewhere.',
    name: 'Ben Knill',
    title: <>Founder of <Link url="https://showhow.co/" shouldOpenNewTab>ShowHow</Link></>,
    img: {
        src: '/images/showhow/ben_knill_256.jpg',
    },
},{
    id: 'pdg',
    content: 'We were looking for a UX designer when we decided to work with Systems for Humans. What we quickly found was that not only did they deliver on exceptional user experience, but they also understood our business needs and delivered a developer-friendly solution that ended up streamlining the entire project and actually saving development time.',
    name: 'Alex Jawad',
    title: <>CEO at Property Deal Genie</>,
    img: {
        src: '/images/30k/alex_jawad_192.jpg',
    },
},{
    id: '30k',
    content: 'We were looking for a UX designer when we decided to work with Systems for Humans. What we quickly found was that not only did they deliver on exceptional user experience, but they also understood our business needs and delivered a developer-friendly solution that ended up streamlining the entire project and actually saving development time.',
    name: 'Alex Jawad',
    title: <>CEO at <Link url="https://www.30k.com/" shouldOpenNewTab>30K Inc.</Link></>,
    img: {
        src: '/images/30k/alex_jawad_192.jpg',
    },
},{
    id: 'avenon',
    content: 'It has been an extremely pleasant time working with Marie on my project. She is a skilled professional with high integrity level both professionally and on a personal level. All tasks completed before deadlines, communication was swift and friendly, plus she was able to make my visions come through graphically. She exceeded my expectations in all areas of this project. I can only recommend her warmly to anyone looking to hire a professional in her skills area 😊',
    name: 'Sari Martikainen',
    title: 'Founder of Avenon',
    img: {
        src: '',
    },
},{
    id: 'moming',
    content: 'Marie is a talented designer, and she knows exactly what you ask for. Marie has been a pleasure to work with, bringing her attention to detail. Her communication and skills are excellent, which makes our project run more smoothly. Also, Marie is always inspiring, cheerful and helpful, she brings great ideas to make the design even better. Highly recommended!',
    name: 'Shi Lun Zhang',
    title: 'Founder of Moming Inc.',
    img: {
        src: '',
    },
},{
    id: 'atze',
    content: 'Marie is a delight to work with and her work is of the highest quality and her timeliness and communication are second to none. Will do more work with Marie in the future when projects come up again.',
    name: 'Andy Kalleske',
    title: <>Owner of <Link url="https://atzes.com/" shouldOpenNewTab>Atze's Corner</Link></>,
    img: {
        src: '/images/atze/andy_kalleske_256.jpg',
    },
}];

export const emails = {
    legal: 'legal@systemsforhumans.com',
    contact: 'hi@systemsforhumans.com',
};

export const notFound = {
    pageTitle: '404: Not found',
    title: 'Page not found',
    subtitle: 'Whoops, you’ve reached this website’s terra incognita.',
};