import React from 'react';
import form from '../../data/contact';

const ContactNetlify = ({ ...rest }) => {

    const getField = field => {

        let Element = 'input';
        let type = 'text';

        switch(field.component){
            case 'Textarea':
                Element = 'textarea';
                type = null;
                break;
            case 'Checkbox':
                type = 'checkbox';
                break;
            case 'Input':
                type = field.type;
                break;
            default: 
        }

        return <Element type={type} name={field.name} key={field.id} />

    };

    return (
        <form name={form.name} data-netlify="true" data-netlify-honeypot="bot-field" hidden {...rest}>
            <input type="hidden" name="form-name" value={form.name} />
            <input type="hidden" name="bot-field" />
            {
                Object.values(form.fields).map(field => getField(field))
            }
        </form>
    );
};

export default ContactNetlify;