import React, { useRef, useEffect, useCallback, useMemo } from "react";
import pt from 'prop-types';
import { easing } from "popmotion";
import { useMotionValue, useTransform } from 'framer-motion';
import { getAnimationDuration, getAnimationEasing, getSpaceValue } from "@arturpol/style-guide/src/js/util";

const getArcMotionProps = (style = {}, delta = null, duration = 0, delay = 0) => {

    if(!delta) delta = { translateX: 500, translateY: 500, rotateZ: -15 };
    if(duration <= 0) duration = getAnimationDuration('slowest');

    const on = { ...style };
    let off = {};

    for(let i in delta){
        
        if(!delta.hasOwnProperty(i)) continue;
        const initial = style.hasOwnProperty(i) ? style[i] : 0;
        off[i] = initial + delta[i];

    }

    return {
        style,
        variants: { on, off },
        transition: {
            delay,
            translateX: {
                ease: getAnimationEasing('easeOut'),
                duration: duration,
            },
            translateY: {
                ease: getAnimationEasing('ease'),
                duration: duration,
            },
            rotateZ: {
                duration: duration,
                ease: getAnimationEasing('easeOutSoft'),
            },
        },
    };

};

const getScaleBouceProps = (scale = 1, duration = 0, delay = 0) => {

    if(duration <= 0) duration = getAnimationDuration('slow');

    return {
        variants: {
            on: {
                scale,
            },
            off: {
                scale: 0,
            },
        },
        transition: {
            duration: duration,
            delay: delay,
            ease: getAnimationEasing('easeBack'),
        }
    };

};

const getFadeInProps = (distance = -4, duration = 0, delay = 0) => {

    if(distance === null) distance = -4;
    if(!duration || duration <= 0) duration = getAnimationDuration('slower');

    return {
        isAnimated: true,
        variants: {
            on: {
                opacity: 1,
                y: 0,
            },
            off: {
                opacity: 0,
                y: getSpaceValue(distance),
            },
        },
        transition: {
            duration,
            delay,
            ease: getAnimationEasing('easeSoft'),
        },
    };

};

const getPathDrawingProps = (style, duration = 0, delay = 0) => {

    if(!duration || duration <= 0) duration = getAnimationDuration('slowest') * 2;

    const originalStyle = {
        ...style,
        pathLength: 1,
        opacity: 1,
    };
    
    return {
        style: originalStyle,
        variants: {
            on: {
                ...originalStyle,
            },
            off: {
                pathLength: 0,
                opacity: 0,
            },
        },
        transition: {
            delay,
            duration,
            ease: getAnimationEasing('easeStrong'),
        },
    };

};

const useAnimationFrame = callback => {
    
    const requestRef = useRef();
    const previousTimeRef = useRef();
    
    useEffect(() => {

        const animate = time => {
        
            if (previousTimeRef.current !== undefined) {
                const deltaTime = time - previousTimeRef.current;
                callback(deltaTime);
            }
            
            previousTimeRef.current = time;
            requestRef.current = requestAnimationFrame(animate);
    
        }

        requestRef.current = requestAnimationFrame(animate);
        return () => cancelAnimationFrame(requestRef.current);

    }, [callback]);

};

const useTotalDuration = (animations) => {

    return useMemo(() => {

        const descending = (a, b) => b - a;
        
        const totalDurations = animations.map(animation => {
            
            let durations = [];
            
            animation.forEach((i, index) => {
                if(index % 2 === 1) durations.push(animation[index - 1] + i);
            });

            durations = durations.sort(descending);

            return durations.length > 0 ? durations[0] : 0;

        }).sort(descending);

        return totalDurations.length > 0 ? totalDurations[0] : 0;

    }, [animations]);

};

const useAnimationProp = (elapsed, timeDurations, values, easingTypes) => {

    const linear = x => Number.isNaN(x) ? 0 : x;

    // duration to end time transformation
    const timeRanges = timeDurations.map((i, index, arr) => {
        return index % 2 === 0 ? i : arr[index - 1] + i;
    });

    const valueRanges = values.concat([]);

    // duplicate value if it should remain constant
    if(valueRanges.length === 1) valueRanges.push(valueRanges[0]);
    
    const settings = { 
        clamp: true,
        ease: easingTypes.map(type => {
            
            if(type !== 'linear' && type !== 'static') return easing.cubicBezier.apply(this, getAnimationEasing(type));
            return linear;

        }),
    };
    
    return useTransform(elapsed, timeRanges, valueRanges, settings);

};

const AnimationDuration = ({ isAnimating, animation }) => {

    const elapsed = useMotionValue(0);

    const onAnimationFrame = useCallback((delta) => {

        if(!isAnimating) return;
        const seconds = elapsed.get() + delta / 1000;
        elapsed.set(seconds);

    }, [elapsed, isAnimating]);
    
    useAnimationFrame(onAnimationFrame);

    const AnimationComponent = animation;

    return <AnimationComponent duration={elapsed} />;

};

AnimationDuration.propTypes = {
    isAnimating: pt.bool,
    animation: pt.any,
};

AnimationDuration.defaultProps = {
    isAnimating: false,
};

export { getArcMotionProps, getScaleBouceProps, getFadeInProps, getPathDrawingProps, 
    useAnimationFrame, useTotalDuration, useAnimationProp, AnimationDuration };