import React, { useMemo } from "react";
import pt from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { AnimatePresence } from 'framer-motion';
import { Root, Element } from '@arturpol/style-guide/src/components';
import { Header, Footer, Client, NavigationMobile, Cookie, ContactNetlify } from '../';
import './layout.scss';
import * as layout from "../../data/layout";
import { getAnimationDuration, getAnimationEasing, getSpaceValue } from "@arturpol/style-guide/src/js/util";

const Layout = ({ children, location }) => {

	const duration = getAnimationDuration('slow');
	const delay = getAnimationDuration('normal');
	const ease = getAnimationEasing('ease');

	const data = useStaticQuery(graphql`
    	query SiteTitleQuery {
			site {
    			siteMetadata {
					title
    			}
    		}
		}
	`);

	const key = location && location.hasOwnProperty('pathname') ? location.pathname : '404';

	const animationProps = useMemo(() => {

		const delta = getSpaceValue(2);

		return {
			isAnimated: true,
			key,
			initial: 'initial',
			animate: 'enter',
			exit: 'exit',
			variants: {
				initial: {
					opacity: 0,
					y: delta,
				},
				enter: {
					opacity: 1,
					y: 0,
					transition: {
						duration,
						delay,
						when: 'beforeChildren',
						ease,
					},
				},
				exit: {
					opacity: 0,
					y: -1 * delta,
					transition: {
						duration,
						ease,
					}
				}
			}
		};

	}, [key, duration, delay, ease]);

	const head = [
		<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" key="favicon1" />,
		<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" key="favicon2" />,
		<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" key="favicon3" />,
		<link rel="manifest" href="/site.webmanifest" key="favicon4" />,
		<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" key="favicon5" />,
		<meta name="msapplication-TileColor" content="#ffc40d" key="favicon6" />,
		<meta name="theme-color" content="#ffd740" key="favicon7" />,
		<style key="style1">{`@import url('${layout.fontStylesheetUrl}');`}</style>
	];
	
	return (
		<Root isServerSide theme="light" head={head}>
			<Header title={data.site.siteMetadata.title} />
			<AnimatePresence>
				<Element as="main" className="main" {...animationProps}>
					{ children }
				</Element>
			</AnimatePresence>
			<Footer title={data.site.siteMetadata.title} />
			<NavigationMobile />
			<Client />
			<Cookie />
			<ContactNetlify />
		</Root>
	);

}

Layout.propTypes = {
	children: pt.node.isRequired,
};

export default Layout;