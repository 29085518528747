import React from "react";
import { Box, Heading, Flex, FlexItem, Breakpoint, Container, Paragraph } from '@arturpol/style-guide/src/components';
import { Ruler, OfferItem, BoxIcon, Project, CalloutFeatures, Seo, Button, QuoteSlider, HomeBanner } from '../../components';
import { useProjects } from '../../util';
import '../../images/home-banner-line.svg';
import blob2 from '../../images/blob2.svg';
import blob3 from '../../images/blob3.svg';
import blob4 from '../../images/blob4.svg';
import blob5 from '../../images/blob5.svg';
import './home.scss';
import * as global from '../../data/global';

const TemplateHome = ({ meta, banner, offer, portfolio, testimonials, why }) => {
    
    const projects = useProjects(3);

	const quotes = testimonials.quotes.map(id => {
    
		const matches = global.quotes.filter((quote) => quote.id === id);
		return matches.length > 0 ? matches[0] : null;
	
	}).filter(i => i !== null);

    return (
        <>
            <Seo title={meta.seoTitle} />
            <HomeBanner data={banner} />
            <Box as="section" className="home-offer" paddingBottom={{ 's': 0, 'm': 4 }} paddingTop={{'sOnly': 6}}>
                <Breakpoint m={<img className="blob-home-offer" src={blob3} alt="" />} />
                <Container>
                    <Flex wrap="wrap" alignItemsY="stretch" gapX="margin" alignItemsX="center">
                        {
                            offer.items.map(i => (
                                <FlexItem span={{'m': 6, 'l': 4}} key={i.title.toLowerCase()}>
                                    <OfferItem data={i}></OfferItem>
                                </FlexItem>
                            ))
                        }
                        <Breakpoint m={ 
                            <BoxIcon name="plus" />
                        } />
                    </Flex>
                </Container>
                <Container align={ { 'm': 'center' } }>
                    <Button url={offer.cta.url} title={offer.cta.hint}>{offer.cta.label}</Button>
                </Container>
            </Box>
            <Box as="section" className="home-why" bg={['gray', 4]} padding={{'s': [8, 0, 6], 'm': [8, 0, 4]}} margin={[6, 0, 8]}>
                <Container>
                    <Flex gapX={{'l': 'margin'}}>
                        <FlexItem pos={{'l': 1}} span={{'l': 10}}>
                            <Heading size={6} importance={4} isAlternative>{why.title}</Heading>
                            <Ruler marginTop={3} marginBottom={4} />
                            {why.sections.map((section, index) => (
                                <React.Fragment key={index}>
                                    <Heading size={3} importance={3}>{section.title}</Heading>
                                    <Flex as="ul" wrap="wrap" gapX={{'m': 'margin'}} marginTop={3}>
                                        {section.items.map((item, index) => (
                                            <FlexItem as="li" span={{'m': 4}} marginBottom={{'s': 2, 'm': 4}} key={index}>
                                                <Heading size={2} importance={4} isAlternative>{item.title}</Heading>
                                                <Paragraph dangerouslySetInnerHTML={{ __html: item.content }}></Paragraph>
                                            </FlexItem>
                                        ))}
                                    </Flex>
                                </React.Fragment>
                            ))}
                        </FlexItem>
                    </Flex>
                </Container>
            </Box>
            <Box as="section" className="home-projects-top" paddingTop={{ 'm': 4 }}>
                <Breakpoint m={<img className="blob-home-projects-1" src={blob2} alt="" />} />
                <Container>
                    <Flex alignItemsX="center" marginBottom={{ 's': 4, 'm': 6 }}>
                        <FlexItem span={{'m': 7, 'l': 4}} align={{ 'm': 'center' }}>
                            <Heading importance={3} size={4}>{portfolio.title}</Heading>
                            {portfolio.content}
                        </FlexItem>
                    </Flex>
                    { 
                        projects.slice(0, 2).map(i => (
                            <Project as="article" data={i} key={i.id} />
                        ))
                    }
                </Container>
            </Box>
            <CalloutFeatures data={global.callout.features} marginTop={{'m': -16}} marginBottom={{'m': -8}} paddingTop={{'m': 14}} paddingBottom={{'s': 8, 'm': 14}} borderTop={null} />
            <Box as="section" className="home-projects-bottom" marginBottom={14}>
                <Breakpoint m={<img className="blob-home-projects-2" src={blob4} alt="" />} />
                <Container>
                    { 
                        projects.slice(2, 3).map(i => (
                            <Project as="article" data={i} key={i.id} />
                        ))
                    }
                </Container>
            </Box>
            <Box as="section" className="home-testimonials" align={{ 'm': 'center' }} paddingBottom={12}>
                <Breakpoint m={<img className="blob-home-testimonials" src={blob5} alt="" />} />
                <Container>
                    <Heading importance={5} size={4}>{testimonials.title}</Heading>
                    <QuoteSlider data={quotes} margin={{'s': [5, -2, 4], 'm': [8, 0]}} />
                    <Button iconRight="long-arrow-alt-right" url={testimonials.cta.url} title={testimonials.cta.hint}>{testimonials.cta.label}</Button>
                </Container>
            </Box>
        </>
    );

};

export default TemplateHome;