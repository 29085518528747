import React from "react";
import pt from "prop-types";

import { Flex, FlexItem, Paragraph, Image, Heading } from '@arturpol/style-guide/src/components';
import { appendHtmlClass } from '@arturpol/style-guide/src/js/util';

import { HashtagList, SecondaryButton } from '../';

import './project.scss';

import * as global from '../../data/global';

const Project = ({ className, data, isSummaryHidden, ...rest }) => {
    
    className = appendHtmlClass(className, 'project');

    const pos = isSummaryHidden ? undefined : {'l': 1};
    const span = isSummaryHidden ? undefined : {'l': 10};

    return (
        <Flex className={className} wrap="wrap" gapX={{ 'l': 'margin' }} alignItemsY="center" marginLeft={{ 'sOnly': -2 }} marginRight={{ 'sOnly': -2 }} shadow={{ 'mDown': 6 }} marginBottom={10} {...rest}>
            <FlexItem as="figure" className="project-thumb" pos={pos} span={span} shadow={{ 'l': 6 }}>
                <Image {...data.images.hero} alt={data.title} />
            </FlexItem>
            { 
                !isSummaryHidden && 
                <FlexItem className="project-summary" shadow={{ 'l': 5 }} padding={[5, 4, 3, 4]} bg="white">
                    <Heading size={2} importance={4} isAlternative>{data.title}</Heading>
                    <Paragraph>{data.excerpt}</Paragraph>
                    <HashtagList data={data.tags} />
                    <SecondaryButton iconRight="long-arrow-alt-right" title={data.hint} url={data.url}>{global.text.more}</SecondaryButton>
                </FlexItem>
            }
        </Flex>
    );

}

Project.propTypes = {
    data: pt.object,
    isSummaryHidden: pt.bool,
};

Project.defaultProps = {
    isSummaryHidden: false,
};

export default Project;