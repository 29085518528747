import React, { useMemo, useState, useCallback } from "react";
import pt from 'prop-types';
import { motion } from 'framer-motion';
import { appendHtmlClass, getAnimationDuration, getAnimationEasing } from '@arturpol/style-guide/src/js/util';

// Original viewbox: 0 0 963 363

const Logo = ({ className, title, fitToHeight, isAnimated, isAnimatedOnHover, ...rest }) => {

    const [isHovered, setIsHovered] = useState(false);

    const onMouseEnter = useCallback(event => setIsHovered(true), [setIsHovered]);
    const onMouseLeave = useCallback(event => setIsHovered(false), [setIsHovered]);

    const style = fitToHeight ? { height: '100%', width: 'auto' } : { width: '100%', height: 'auto' };

    const totalDuration = getAnimationDuration('slower') * 2;
    const eyeDuration = getAnimationDuration('fast');

    const faceTransition = useMemo(() => {

        const faceDuration = totalDuration - eyeDuration;
        const faceRelativeDuration = (faceDuration - eyeDuration) / (faceDuration * 2);

        return {
            duration: faceDuration,
            ease: getAnimationEasing('easeBack'),
            times: [0, faceRelativeDuration, 1 - faceRelativeDuration, 1],
        };

    }, [totalDuration, eyeDuration]);

    const faceProps = useMemo(() => {

        const style = {
            originX: 0.5, 
            originY: 0.5, 
            translateX: 74.44, 
            translateY: 75.22,
            rotateZ: 0,
        };

        const on = {
            rotateZ: [null, 90, 90, 0],
        };

        const off = {
            rotateZ: 0,
        };

        return {
            style,
            variants: { on, off },
            transition: faceTransition,
        };

    }, [faceTransition]);

    const getFrameProps = useCallback((isLeft = false) => {

        const distance = 20;
        const max = distance * (isLeft ? -1 : 1);

        const style = {
            translateX: 0,
        };

        const on = {
            translateX: [null, max, max, 0],
        };

        const off = {
            translateX: 0,
        };

        return {
            style,
            variants: { on, off },
            transition: faceTransition,
        };

    }, [faceTransition]);

    const eyeTransition = useMemo(() => {
        
        const delay = (totalDuration - 2 * eyeDuration) / 2;
        const duration = totalDuration - delay;
        const relativeDuration = eyeDuration / duration;
        
        return {
            duration: duration,
            ease: getAnimationEasing('easeStrong'),
            times: [0, relativeDuration, 1 - relativeDuration, 1],
            delay,
        };

    }, [eyeDuration, totalDuration]);

    const eyeOpenProps = useMemo(() => {

        const targetScale = 3.5;

        const style = {
            scaleX: 1,
        };

        const on = {
            scaleX: [null, targetScale, targetScale, 1],
        };

        const off = {
            scaleX: 1,
        };

        return {
            style,
            variants: { on, off },
            transition: eyeTransition,
        };

    }, [eyeTransition]);

    const eyeCloseProps = useMemo(() => {

        const style = {
            scaleX: 1,
            opacity: 1,
        };

        const on = {
            scaleX: [null, 0, 0, 1],
            opacity: [null, 0, 0, 1],
        };

        const off = {
            scaleX: 1,
            opacity: 1,
        };

        return {
            style,
            variants: { on, off },
            transition: eyeTransition,
        };

    }, [eyeTransition]);

    const frameLeftProps = useMemo(() => getFrameProps(true), [getFrameProps]);
    const frameRightProps = useMemo(() => getFrameProps(false), [getFrameProps]);

    const isOn = isAnimated || (isAnimatedOnHover && isHovered);

    className = appendHtmlClass(className, 'logo');

	return (
        <motion.svg className={className} viewBox="-20 0 1003 378" preserveAspectRatio="xMidYMin meet" version="1.1" xmlns="http://www.w3.org/2000/svg" style={style} animate={isOn ? 'on' : 'off'} initial={isAnimated ? 'on': false} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} {...rest}>
            <title>{title}</title>
            <g id="logo-full" stroke="none" strokeWidth="1" fill="#000000" fillRule="nonzero">
                <g id="logo-text" transform="translate(436.780000, 91.650000)">
                    <g id="logo-forhumans" transform="translate(0.020000, 110.989989)">
                        <path d="M9.2,72.4300107 L9.2,31.1300107 L2.84217094e-14,31.1300107 L2.84217094e-14,23.6000107 L9.2,23.6000107 L9.2,17.7000107 C9.2,4.07001072 17.95,-1.42108547e-14 26.5,-1.42108547e-14 C30.672466,-0.00329053625 34.8104195,0.755842615 38.71,2.24001072 L38.71,9.77001072 C35.4810246,8.30257998 31.9767667,7.53900629 28.43,7.53001072 C22.53,7.53001072 17.34,9.05001072 17.34,18.0100107 L17.34,23.6000107 L35.6,23.6000107 L35.6,31.1300107 L17.29,31.1300107 L17.29,72.4300107 L9.2,72.4300107 Z" id="Path"></path>
                        <path d="M65.41,22.3600107 C78.74,22.3600107 91.46,31.3100107 91.46,48.0000107 C91.46,64.6900107 78.74,73.6300107 65.41,73.6300107 C52.08,73.6300107 39.41,64.6300107 39.41,48.0000107 C39.41,31.3700107 52.2,22.3600107 65.41,22.3600107 Z M65.41,30.0900107 C55.41,30.0900107 47.51,36.7000107 47.51,48.0000107 C47.51,59.3000107 55.44,65.9000107 65.41,65.9000107 C75.38,65.9000107 83.32,59.2900107 83.32,48.0000107 C83.32,36.7100107 75.38,30.1100107 65.41,30.1100107 L65.41,30.0900107 Z" id="Shape"></path>
                        <path d="M131.54,22.5800107 L131.54,30.7200107 C121.98,29.6000107 114.25,34.1800107 111.3,42.0100107 L111.3,72.4300107 L103.2,72.4300107 L103.2,23.6000107 L110.52,23.6000107 L110.52,32.2500107 C114.35,25.8400107 123,21.8700107 131.54,22.5800107 Z" id="Path"></path>
                        <path d="M171.63,1.22001072 L171.63,29.8100107 C175.946738,25.1051478 182.025033,22.4065005 188.41,22.3600107 C200.11,22.3600107 208.76,30.3600107 208.76,42.3000107 L208.76,72.4100107 L200.62,72.4100107 L200.62,43.3600107 C200.62,34.2000107 195.02,29.9300107 187.5,29.9300107 C180.27,29.9300107 174.88,34.8100107 171.63,39.6900107 L171.63,72.4500107 L163.49,72.4500107 L163.49,1.22001072 L171.63,1.22001072 Z" id="Path"></path>
                        <path d="M267.87,23.6000107 L267.87,72.4300107 L260.54,72.4300107 L260.54,65.2100107 C256.24321,70.5296198 249.778146,73.6299122 242.94,73.6500107 C230.23,73.6500107 222.6,65.6500107 222.6,53.7100107 L222.6,23.6000107 L230.73,23.6000107 L230.73,52.7000107 C230.73,61.8500107 235.31,66.1200107 243.86,66.1200107 C251.08,66.1200107 256.47,61.2400107 259.73,56.3600107 L259.73,23.6000107 L267.87,23.6000107 Z" id="Path"></path>
                        <path d="M282.92,72.4300107 L282.92,23.6000107 L290.25,23.6000107 L290.25,30.1100107 C292.89,27.2600107 298.69,22.3800107 306.93,22.3800107 C314.26,22.3800107 319.65,25.5300107 322.6,30.6200107 C326.97,26.1400107 332.98,22.3800107 340.4,22.3800107 C352.51,22.3800107 359.02,30.9300107 359.02,42.3200107 L359.02,72.4300107 L350.88,72.4300107 L350.88,42.1200107 C350.88,34.6900107 346.61,29.9100107 339.49,29.9100107 C332.87,29.9100107 327.99,34.4900107 325.24,37.9100107 C325.451577,39.3569725 325.555196,40.8176715 325.55,42.2800107 L325.55,72.3900107 L317.41,72.3900107 L317.41,42.1200107 C317.41,34.6900107 313.14,29.9100107 306.02,29.9100107 C298.9,29.9100107 293.71,35.3000107 291.02,38.7600107 L291.02,72.4300107 L282.92,72.4300107 Z" id="Path"></path>
                        <path d="M416.91,66.0200107 C418.192895,65.9827573 419.461858,65.7431392 420.67,65.3100107 L420.67,71.9200107 C418.716783,72.6434668 416.652819,73.0224241 414.57,73.0400107 C410.57,73.0400107 407.04,71.4100107 405.57,67.1400107 C400.68,71.2100107 394.48,73.6500107 386.57,73.6500107 C377.93,73.6500107 369.38,69.0700107 369.38,58.8000107 C369.38,46.1900107 380.98,42.3200107 391.46,42.3200107 C396.010622,42.3632509 400.544635,42.8759664 404.99,43.8500107 L404.99,42.1200107 C404.99,34.7900107 399.99,29.9100107 391.05,29.9100107 C384.928965,29.9014666 378.871079,31.1470768 373.25,33.5700107 L373.25,25.6400107 C379.236993,23.5281727 385.531744,22.4199181 391.88,22.3600107 C404.88,22.3600107 413.14,29.4800107 413.14,43.1100107 L413.14,62.2600107 C413.14,65.2100107 414.77,66.0200107 416.91,66.0200107 Z M388.32,66.6300693 C394.623994,66.6442269 400.657509,64.0703128 405.01,59.5100107 L405.01,50.5600107 C400.925364,49.7087781 396.771345,49.2334593 392.6,49.1400107 C385.47,49.1400107 377.74,51.2700107 377.74,58.3900107 C377.74,64.0900107 382.93,66.6300693 388.32,66.6300693 L388.32,66.6300693 Z" id="Shape"></path>
                        <path d="M453.74,22.3600107 C465.44,22.3600107 474.08,30.3600107 474.08,42.3000107 L474.08,72.4100107 L465.95,72.4100107 L465.95,43.3600107 C465.95,34.2000107 460.35,29.9300107 452.82,29.9300107 C445.6,29.9300107 440.21,34.8100107 436.95,39.6900107 L436.95,72.4500107 L428.81,72.4500107 L428.81,23.6000107 L436.14,23.6000107 L436.14,30.8200107 C440.432859,25.4929148 446.898512,22.3849931 453.74,22.3600107 L453.74,22.3600107 Z" id="Path"></path>
                        <path d="M504.3,22.3599768 C510.313333,22.3519499 516.24145,23.7816924 521.59,26.5300107 L521.59,34.2600107 C517.52,31.6200107 510.71,29.6800107 504.81,29.6800107 C499.52,29.6800107 493.92,31.4100107 493.92,36.2000107 C493.92,46.7800107 524.92,42.3000107 524.92,59.9000107 C524.92,70.0700107 515.36,73.6300107 504.78,73.6300107 C497.86,73.6300107 490.43,71.8000107 485.86,68.8500107 L485.86,60.9200107 C490.86,64.2700107 498.27,66.1000107 504.37,66.1000107 C511.29,66.1000107 516.78,64.9800107 516.78,60.1000107 C516.78,49.8300107 485.65,53.9000107 485.65,36.4000107 C485.68,26.3600107 495.14,22.3599768 504.3,22.3599768 Z" id="Path"></path>
                    </g>
                    <g id="logo-systems">
                        <path d="M29.76,2.84217094e-14 C39.41,2.84217094e-14 49.17,2.32 56.15,6.28 L56.15,22.55 C49.29,17.67 40.15,15.23 31.04,15.35 C22.2,15.46 17.78,17.9 17.78,22.9 C17.78,36.97 62.31,31.74 62.31,60.69 C62.31,78.94 47.66,84.64 30.57,84.64 C20.0800532,84.7801469 9.72413274,82.2728517 0.46,77.35 L0.46,60.69 C9.01572343,66.3282458 19.0435691,69.319556 29.29,69.29 C38.71,69.29 44.52,67.29 44.52,61.5 C44.52,46.27 2.84217094e-14,51.97 2.84217094e-14,23.14 C2.84217094e-14,7.44 13.72,2.84217094e-14 29.76,2.84217094e-14 Z" id="Path"></path>
                        <polygon id="Path" points="94.52 83.01 94.52 54.18 62.66 1.63 82.31 1.63 103.12 38.13 123.93 1.63 143.58 1.63 111.84 54.18 111.84 83.01"></polygon>
                        <path d="M175.44,2.84217094e-14 C185.09,2.84217094e-14 194.85,2.32 201.83,6.28 L201.83,22.55 C194.97,17.67 185.78,15.23 176.71,15.35 C167.88,15.46 163.46,17.9 163.46,22.9 C163.46,36.97 207.99,31.74 207.99,60.69 C207.99,78.94 193.34,84.64 176.25,84.64 C165.759907,84.7814426 155.40367,82.2740705 146.14,77.35 L146.14,60.69 C154.695723,66.3282458 164.723569,69.319556 174.97,69.29 C184.39,69.29 190.2,67.29 190.2,61.5 C190.2,46.27 145.67,51.97 145.67,23.14 C145.67,7.44 159.39,2.84217094e-14 175.44,2.84217094e-14 Z" id="Path"></path>
                        <polygon id="Path" points="278.79 1.63 278.79 17.35 253.79 17.35 253.79 83.04 236.47 83.04 236.47 17.35 211.47 17.35 211.47 1.63"></polygon>
                        <polygon id="Path" points="346.34 1.63 346.34 16.97 306.46 16.97 306.46 34.35 342.85 34.35 342.85 49.57 306.46 49.57 306.46 67.71 347.04 67.71 347.04 83.06 289.04 83.06 289.04 1.63"></polygon>
                        <polygon id="Path" points="448.88 1.63 448.88 83.01 431.44 83.01 431.44 32.9 409.93 65.57 399.82 65.57 378.31 32.78 378.31 83.01 360.87 83.01 360.87 1.63 377.03 1.63 404.94 43.94 432.72 1.63"></polygon>
                        <path d="M492.48,1.42108547e-14 C502.13,1.42108547e-14 511.9,2.32 518.87,6.28 L518.87,22.55 C512.01,17.67 502.87,15.23 493.76,15.35 C484.92,15.46 480.51,17.9 480.51,22.9 C480.51,36.97 525.03,31.74 525.03,60.69 C525.03,78.94 510.38,84.64 493.3,84.64 C482.820229,84.774662 472.475351,82.2675423 463.22,77.35 L463.22,60.69 C471.780082,66.3268745 481.81068,69.3179683 492.06,69.29 C501.47,69.29 507.29,67.29 507.29,61.5 C507.29,46.27 462.759913,51.97 462.759913,23.14 C462.72,7.44 476.44,1.42108547e-14 492.48,1.42108547e-14 Z" id="Path"></path>
                    </g>
                </g>
                <g id="logo-icon">
                    <motion.g id="logo-face" {...faceProps}>
                        <path d="M224.01,106.26 C224.01,49.88 179.71,3.6 124.59,-2.84217094e-14 L124.59,27.7 C165.297425,31.3211756 196.523125,65.3918845 196.59,106.26 C196.59,147.49 164.98,181.43 124.86,184.82 L124.56,212.55 C179.96,209.23 224.01,162.78 224.01,106.26 Z" id="smile"></path>
                        <path d="M43.62,124.5 C19.5293392,124.5 0,144.029339 0,168.12 C0,192.210661 19.5293392,211.74 43.62,211.74 C67.7106608,211.74 87.24,192.210661 87.24,168.12 C87.24,144.029339 67.7106608,124.5 43.62,124.5 Z M43.62,188.22 C32.5190765,188.22 23.52,179.220923 23.52,168.12 C23.52,157.019077 32.5190765,148.02 43.62,148.02 C54.7209235,148.02 63.72,157.019077 63.72,168.12 C63.72,179.220923 54.7209235,188.22 43.62,188.22 L43.62,188.22 Z" id="eyeBottom"></path>
                        <motion.path id="eyeTopOpen" {...eyeOpenProps} d="M43.56,0.78 C36.932583,0.78 31.56,20.2556134 31.56,44.28 C31.56,68.3043866 36.932583,87.78 43.56,87.78 C50.187417,87.78 55.56,68.3043866 55.56,44.28 C55.56,20.2556134 50.187417,0.78 43.56,0.78 Z M44.06,63.78 C41.0224339,63.78 38.56,54.825695 38.56,43.78 C38.56,32.734305 41.0224339,23.78 44.06,23.78 C47.0975661,23.78 49.56,32.734305 49.56,43.78 C49.56,54.825695 47.0975661,63.78 44.06,63.78 L44.06,63.78 Z"></motion.path>
                        <motion.polygon id="eyeTopClose" opacity="1" {...eyeCloseProps} points="55.74 87.78 55.74 0.35 5.58 0.16 5.49 24.11 31.78 24.21 31.78 87.78"></motion.polygon>
                    </motion.g>
                    <g id="logo-frame">
                        <motion.polygon id="logo-frame-left" {...frameLeftProps} points="74.32 337.11 25.1 337.11 25.1 25.1 74.32 25.1 74.32 0 -2.84217094e-14 0 -2.84217094e-14 362.21 74.32 362.21"></motion.polygon>
                        <motion.polygon id="logo-frame-right" {...frameRightProps} points="299.78 0 299.78 25.1 349 25.1 349 337.11 299.78 337.11 299.78 362.21 374.1 362.21 374.1 0"></motion.polygon>
                    </g>
                </g>
            </g>
        </motion.svg>
    );

}

Logo.propTypes = {
    fitToHeight: pt.bool,
    isAnimated: pt.bool,
    isAnimatedOnHover: pt.bool,
};

Logo.defaultProps = {
    fitToHeight: false,
    isAnimated: false,
    isAnimatedOnHover: false,
};

export default Logo;