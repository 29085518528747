import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { getSpaceValue } from "@arturpol/style-guide/src/js/util";
import { Drawer } from '@arturpol/style-guide/src/components';
import * as layout from "../../data/layout";

const Client = () => {

	const PARSE_FAKE_DOMAIN = 'http://fake.domain';
	
	const [clientPage, setClientPage] = useState(null);
	const ref = useRef(null);

	const data = useStaticQuery(graphql`
		query ClientPagesQuery {
			allMdx(filter: {exports: {meta: {type: {eq: "client"}}}}) {
				edges {
					node {
						exports {
							meta {
								hint
								order
								published
								title
								type
								isPartiallyActive
							}
						}
						fields {
							slug
						}
						body
					}
				}
			}
		}`
	);

	const onDrawerClose = useCallback((info, event) => {
		
		window.location.hash = '';

	}, []);

	const close = useCallback(() => {
		
		setClientPage(null);
		onDrawerClose();

	}, [setClientPage, onDrawerClose]);

	const render = useCallback(({template, children, ...props}) => {

		const Template = template;
		return Template ? <Template {...props} close={close} drawerRef={ref}>{children}</Template> : <>{children}</>;

	}, [close]);
	
    const content = useMemo(() => {

		if(!clientPage) return null;
		
		const slug = clientPage.url.substring(1, clientPage.url.length);
		const page = data.allMdx.edges.find(page => page.node.fields.slug === slug);

		if(!page) return null;

		return <MDXRenderer render={render} params={clientPage.params}>{page.node.body}</MDXRenderer>
		
	}, [clientPage, render, data.allMdx.edges]);
	
	const parseHash = useCallback(hash => {

		const url = PARSE_FAKE_DOMAIN + hash.substring(1, hash.length);
		const parts = new URL(url);
		
		const path = parts.pathname;
		const search = parts.search;
		let params = {};

		if(search.length > 1){
			
			let base = search.substring(1, search.length);
			let pairs = base.split('&').map(i => {
				
				const pair = i.split('=');
				return pair.length <= 1 ? { [pair[0]]: '' } : { [pair[0]]: pair[1] };

			});

			params = Object.assign.apply(this, pairs);

		}

		return { path, params };

	}, []);

	const onUrlChange = useCallback((url) => {

		let matched = false;
		const parts = new URL(url);

		if(!parts || !parts.hash || parts.hash.length <= 1){
			
			setClientPage(false);

		}else{

			const { path, params } = parseHash(parts.hash);

			for(let i = 0 ; i < layout.navigation.length ; i++){

				const page = layout.navigation[i];
				const hashPath = `#${path}`;
				
				if(hashPath === page.url){
					
					setClientPage({ ...page, params });
					matched = true;
					break;

				}

			}

			if(!matched) setClientPage(false);

		}

	}, [setClientPage, parseHash]);

	useEffect(() => {

		const onHashChange = e => onUrlChange(e.newURL);

		window.addEventListener('hashchange', onHashChange);
		return () => window.removeEventListener('hashchange', onHashChange);

	}, [onUrlChange]);

	useEffect(() => {
		
		onUrlChange(window.location.toString());

	}, [onUrlChange]);
	
	const mediumPaneWidth = getSpaceValue(112);
	const overwrites = { Pane: { span: {'m': 12}, flex: {'m': `0 1 ${mediumPaneWidth}`}, } };
	
	return (
		<Drawer as="aside" className="client-drawer" position="right" size="l" isOpen={!!clientPage} 
			onClose={onDrawerClose} overwrites={overwrites} innerRef={ref}>{content}</Drawer>
	);

}

export default Client;