import React from "react";
import pt from "prop-types";
import { Flex, FlexItem, Link, Icon } from '@arturpol/style-guide/src/components';
import { appendHtmlClass } from '@arturpol/style-guide/src/js/util';

import './navigation.scss';

import * as layout from "../../data/layout";

const NavigationSocial = ({ className, size, ...rest }) => {
    
    className = appendHtmlClass(className, 'nav-social');

    return (
        <Flex as="ul" className={className} alignItemsX="flex-end" {...rest}>
            { 
                layout.navigationSocial.map(i => 
                    <FlexItem as="li" key={i.icon}>
                        <Link type="raw" title={i.hint} url={i.url} shouldOpenNewTab>
                            <Icon size={size} name={['brands', i.icon]} display="iblock" />
                        </Link>
                    </FlexItem>
                )
            }
        </Flex>
    );

}

NavigationSocial.propTypes = {
    size: pt.number,
};

NavigationSocial.defaultProps = {
    size: 3,
};

export default NavigationSocial;