import React from "react";
import { LinkExternal } from '../components';

const form = {
    title: 'Let\'s get in touch',
    name: 'contact',
    defaultValues: {
        subject: 'workshop',
        message: '',
    },
    submit: {
        label: 'Send',
        url: '/submit',
        method: 'GET',
        hint: 'Send message',
        invalid: 'Hang on, the form isn\'t valid.',
    },
    success: {
        title: 'Message sent',
        content: 'Thank you! We\'ll get back to you ASAP.',
        img: {
            src: '/images/message-sent.gif',
            alt: 'Message sent',
        },
        actions: {
            title: 'What do you want to do next?',
            closeButton: {
                label: 'Close',
                hint: 'Close this window',
            },
            resetButton: {
                label: 'Send another message',
                hint: 'Let\'s write another message',
            },
        },
    },
    failure: {
        title: 'Not sent',
        content: 'Something went wrong. Please send your message again.',
    },
    fields: {
        subject: {
            id: 'field-subject',
            name: 'subject',
            label: 'What are you interested in?',
            isRequired: true,
            placeholder: 'Select a product or a topic…',
            component: 'Select',
            options: [{
                id: 'workshop',
                label: 'Free Product Workshop',
                note: {
                    title: 'What to expect?',
                    content: [
                        "First, we’ll talk about your project goals, user needs and your product-market fit.",
                        "Next, we’ll brainstorm potential solutions and if you’re inclined to move forward, an action plan.",
                        "It usually takes about 45 minutes, and it’s fun!",
                    ],
                },
            },{
                id: 'blueprint',
                label: 'Application Blueprint',
            },{
                id: 'branding',
                label: 'Brand Identity',
            },{
                id: 'design',
                label: 'Application Design',
            },{
                id: 'other',
                label: 'Other',
            }],
        },
        name: {
            id: 'field-name',
            name: 'name',
            label: 'What’s your name?',
            isRequired: true,
            placeholder: 'Nice to meet you!',
            component: 'Input',
            type: 'text',
        },
        company: {
            id: 'field-company',
            name: 'company',
            label: 'What’s the name of your company?',
            isRequired: false,
            placeholder: 'A business card? Thank you!',
            component: 'Input',
            type: 'text',
            caption: 'Optional',
        },
        email: {
            id: 'field-email',
            name: 'email',
            label: 'What’s your email?',
            isRequired: true,
            placeholder: 'No worries, we hate spam too.',
            component: 'Input',
            type: 'email',
        },
        message: {
            id: 'field-message',
            name: 'message',
            label: 'Tell us about the problem you want to solve',
            isRequired: true,
            placeholder: 'Just a few words to give us an idea.',
            component: 'Textarea',
        },
        terms: {
            id: 'field-terms',
            name: 'terms',
            content: <span>Agree to <LinkExternal url="/terms/" type="underlined">terms &amp; conditions</LinkExternal> and <LinkExternal url="/privacy/" type="underlined">privacy policy</LinkExternal></span>,
            isRequired: true,
            component: 'Checkbox',
        }
    },
};

export default form;