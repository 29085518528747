import React from "react";
import { Box, Heading, Flex, FlexItem, Container, Breakpoint, Lead, Element, Caption, Paragraph } from '@arturpol/style-guide/src/components';
import { Seo, HashtagList, Project, BoxIcon, RulerForSmallOnly, QuoteBox, ProjectImage, CalloutFeatures, ProjectNav, SecondaryButton } from '../../components';
import { useProjects, usePortfolio } from '../../util';
import projectChart from '../../images/project-chart.svg';
import blob2 from '../../images/blob2.svg';
import blob6 from '../../images/blob6.svg';
import blob1 from '../../images/blob1.svg';
import './project.scss';
import * as global from '../../data/global';

function getQuote(content){
    
    if(!content.hasOwnProperty('quote') || !Array.isArray(global.quotes) || global.quotes.length <= 0){
        return null;
    }
    
    let quotes = global.quotes.filter(i => i.id === content.quote);
    
    return quotes.length > 0 ? quotes[0] : null;

}

function getProject(projects, path, offset){

    const matched = projects
        .map((i, index) => i.url === path ? index : -1 )
        .filter(i => i >= 0);

    if(matched.length <= 0) return null;

    const current = matched[0];
    let requested = current + offset;

    if(requested >= projects.length) requested -= projects.length;
    else if(requested < 0) requested += projects.length;
    
    if(requested < 0 || requested >= projects.length) return null;

    return projects[requested];

}

const ProjectProblem = ({children, title, ...rest}) => {
    
    return (
        <FlexItem span={{'m': 6, 'l': 4}} border={{ 'm': ['solid', 'gray', 3]}} padding={{'s': [0, 2, 4], 'm': [6, 6, 5]}} bg={['white']} {...rest}>
            <Heading size={2} importance={6}>{title}</Heading>
            <Paragraph>{children}</Paragraph>
        </FlexItem>
    );

};

const TemplateProject = ({children, content, meta, path, ...rest}) => { 
    
    const projects = useProjects();
    const portfolio = usePortfolio();
    
    const hasFigures = content.hasOwnProperty('figures') && Array.isArray(content.figures) && content.figures.length > 0;
    const quote = getQuote(content);
    const prev = getProject(projects, path, -1);
    const next = getProject(projects, path, 1);
    
    return (
        <>
            <Seo title={meta.seoTitle} description={meta.seoDescription} />
            <Box as="section" className="project-banner" align={{'m': 'center'}}>
                <Breakpoint m={<img className="blob-project-banner-1" src={blob2} alt="" />} />
                <Breakpoint m={<img className="blob-project-banner-2" src={blob6} alt="" />} />
                <Container>
                    <Flex alignItemsX="center" paddingTop={{'s': 12, 'm': 18}} paddingBottom={{'s': 6, 'm': 10}} gapX={{'m': 'margin'}}>
                        <FlexItem span={{'m': 8, 'l': 6}}>
                            <Heading importance={3} size={2} isAlternative>{portfolio.title}</Heading>
                            <Heading importance={1} size={6}>{meta.title}</Heading>
                            <Box margin={[4, 0, 2]}>
                                <Lead>{content.description}</Lead>
                            </Box>
                            <HashtagList data={content.tags} />
                        </FlexItem>
                    </Flex>
                    <Project data={content} paddingBottom={0} isSummaryHidden />
                </Container>
            </Box>
            <Box as="section" className="project-solution" paddingBottom={{'m': 6}}>
                <Breakpoint m={<img className="blob-project-solution" src={blob1} alt="" />} />
                <Container>
                    <Flex className="project-solution-row" alignItemsX="center" paddingTop={{'s': 2}} paddingBottom={{'s': 4}} gapX={{'m': 'margin'}} alignItemsY={{'m': 'stretch'}} wrap="wrap">
                        <ProjectProblem title={global.text.projectProblemHeading}>
                            {content.problem}
                        </ProjectProblem>
                        <ProjectProblem title={global.text.projectSolutionHeading}>
                            {content.solution}
                        </ProjectProblem>
                        <Breakpoint m={ 
                            <BoxIcon name="plus" />
                        } />
                    </Flex>
                </Container>
            </Box>
            <Element as="section" className="project-content">
                <Container>
                    {children}
                </Container>
            </Element>
            { hasFigures && 
                <>
                    <Box as="section" className="project-figures" align={{'m': 'center'}} marginTop={{'m': -12}} paddingTop={{'s': 6, 'm': 24}} paddingBottom={{'m': 16}}>
                        <img className="project-chart" alt="" src={projectChart} />
                        <Container>
                            <Heading size={4} importance={6}>{global.text.projectFiguresHeading}</Heading>
                            <Flex as="ul" className="project-figures-list" alignItemsX={{'s': 'flex-start', 'm': 'center'}} wrap="wrap" gapX="margin" marginTop={{'s': 8, 'm': 6}}>
                                {
                                    content.figures.map((i, index) => (
                                        <FlexItem as="li" span={{'s': 6, 'm': 3, 'l': 2}} marginBottom={4} key={index}>
                                            <Element as="figure">
                                                <Heading as="div" size={6} isAlternative>{i.value}</Heading>
                                                <Caption as="figcaption">{i.label}</Caption>
                                            </Element>
                                        </FlexItem>
                                    ))
                                }
                            </Flex>
                        </Container>
                    </Box>
                    { quote && <RulerForSmallOnly marginTop={2} /> }
                </>
            }
            { quote && 
                <Box as="section" className="project-quote" paddingTop={2} paddingBottom={6} align={{'m': 'center'}}>
                    <Container>
                        <Flex alignItemsX="center">
                            <FlexItem span={{'m': 10, 'l': 8}}>
                                <Heading as="div" size={4}>{global.text.projectQuoteHeading}</Heading>
                                <Box margin={{'s': [4, 0, 3], 'm': [12, 0, 20]}}>
                                    <QuoteBox data={quote} />
                                </Box>
                            </FlexItem>
                        </Flex>
                    </Container>
                </Box>
            }
            { content.images.bottom &&
                <Box as="section" className="project-bottom" marginBottom={{'s': 2, 'm': 4}}>
                    <Container>
                        <ProjectImage image={content.images.bottom} span={12} />
                    </Container>
                </Box>
            }
            <CalloutFeatures className="callout-large" data={global.callout.features} borderTop={null} borderBottom={null} paddingTop={6} paddingBottom={{'s': 4, 'm': 6}}  />
            <Box as="aside" className="project-nav" padding={{'s': [6, 0, 4], 'm': [4, 0, 2]}} bg={['gray', 4]} align="center" borderBottom={['solid', 'gray', 2]}>
                <Container as="nav">
                    <Flex wrap="wrap" gapX={{'m': 'margin'}} marginLeft={{'sOnly': 2}} marginRight={{'sOnly': 2}} marginBottom={{'s': 4, 'm': 2}}>
                        <FlexItem span={{'s': 12, 'm': 4, 'l': 3}} pos={{'m': 2, 'l': 3}} order={{'s': 1, 'm': 0}}>
                            { prev && <ProjectNav data={prev} isPrevious /> }
                        </FlexItem>
                        <FlexItem span={{'s': 12, 'm': 4, 'l': 3}} order={{'s': 0, 'm': 1}}>
                            { next && <ProjectNav data={next} /> }
                        </FlexItem>
                    </Flex>
                    <SecondaryButton iconLeft="long-arrow-alt-left" url="/portfolio/" title={portfolio.hint}>{global.text.projectBackButton}</SecondaryButton>
                </Container>
            </Box>
        </>
    );

};

export default TemplateProject;