import React from "react";
import { Container, Element, Heading, Flex, FlexItem, Box } from '@arturpol/style-guide/src/components';
import { Seo, Link } from '../../components';
import { useNavigation } from '../../util';
import './legal.scss';

const TemplateLegal = ({ meta, children }) => {
    
    const pages = useNavigation('legal');
    
    return (
        <>
            <Seo title={meta.seoTitle} description={meta.seoDescription} />
            <Container>
                <Flex gapX={{'m': 'margin'}} margin={{'s': [12, 0, 4], 'm': [16, 0, 8]}} wrap="wrap">
                    <FlexItem as="article" span={{'s': 12, 'm': 9, 'l': 7}} marginLeft={{'m': 0}} order={{'s': 2, 'm': 1}}>
                        {children}
                    </FlexItem>
                    <FlexItem as="aside" className="side-nav-wrapper" span={{'s': 12, 'm': 3}} pos={{'l': 1}} marginRight={{'m': 0}} order={{'s': 1, 'm': 2}}>
                        <Box as="nav" className="side-nav" bg={['gray', 4]} padding={{'s': 4, 'm': [5, 4]}} margin={{'s': [0, -2, 5], 'm': [0, 0, 4]}}>
                            <Heading as="div" size={2}>Menu</Heading>
                            <Element as="ul">
                                {pages.map(i => (
                                    <Element as="li" className={i.isActive || i.isPartiallyActive ? 'current' : ''} key={i.url}>
                                        <Link type="raw" url={i.url} title={i.hint}>{i.title}</Link>
                                    </Element>
                                ))}
                            </Element>
                        </Box>
                    </FlexItem>
                </Flex>
            </Container>
        </>
    );

};

export default TemplateLegal;