import React from "react";
import pt from 'prop-types';
import { Flex, FlexItem } from '@arturpol/style-guide/src/components';
import { appendHtmlClass } from '@arturpol/style-guide/src/js/util';
import { Link } from '../../components';
import { useNavigation } from '../../util';
import './navigation.scss';
import { navigation } from '../../data/layout';

const Navigation = ({ className, includeLegal, ...rest }) => {

	className = appendHtmlClass(className, 'nav');
	
	const pages = useNavigation('page', navigation);
	const legal = useNavigation('legal');
	const menuItems = pages.concat(includeLegal ? legal : []).sort((a, b) => a.order - b.order);
	
	return (
		<Flex as="ul" className={className} alignItemsX="center" {...rest}>
			{ 
				menuItems.map(i => {

					let cls = i.isActive ? 'current' : null;
					if(i.isPartiallyActive) cls = appendHtmlClass(cls, 'current-partially');
					
					return (
						<FlexItem as="li" className={cls} key={`${i.order}`}>
							<Link type="raw" title={i.hint} url={i.url} isPartiallyActive={i.isPartiallyActive}>{i.title}</Link>
						</FlexItem>
					);

				})
			}
		</Flex>
	);

};

Navigation.propTypes = {
	includeLegal: pt.bool,
};

Navigation.defaultProps = {
	includeLegal: false,
};

export default Navigation;