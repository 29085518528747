import React from "react";
import pt from "prop-types";
import { Flex, FlexItem, Box, Quote, Breakpoint, Avatar as SfhAvatar } from '@arturpol/style-guide/src/components';
import { appendHtmlClass, getOverwrites } from '@arturpol/style-guide/src/js/util';
import { BoxIcon } from '../';
import './quotebox.scss';

const Avatar = ({data, ...rest}) => <SfhAvatar name={data.name} src={data.img.src} size={5} corner={0} bg={['secondary', -2]} {...rest} />;

const QuoteBox = ({ data, className, overwrites, ...rest }) => {
    
    className = appendHtmlClass(className, 'quote-box');

    const quoteOverwrites = getOverwrites(overwrites, 'Quote');
    const avatarOverwrites = getOverwrites(overwrites, 'Avatar');
    
    return (
        <Box className={className} padding={{'m': [5, 0, 16]}} {...rest}>
            <Flex padding={{ 'm': [12, 8, 10] }} border={{ 'm': ['solid', 'gray', 2] }} bg={['white']} alignItemsY={{'m': 'center'}} flow={{'sOnly': 'column'}}>
                <FlexItem span={{'sOnly': 'auto' }}>
                    <Quote isBlock title={data.title} author={data.name} {...quoteOverwrites}>{data.content}</Quote>
                </FlexItem>
                <Breakpoint m={
                    <BoxIcon name="quote-left" />
                } />
                <FlexItem className="avatar-box" width={{ 's': 8, 'm': 10 }} height={{ 's': 8, 'm': 10 }} border={['solid', 'gray', 2]} padding={1} marginTop={{ 'sOnly': 2 }} bg="white" span="shrink">
                    <Avatar data={data} {...avatarOverwrites} />
                </FlexItem>
            </Flex>
        </Box>
    );

};

QuoteBox.propTypes = {
    data: pt.object,
    overwrites: pt.shape({
        Quote: pt.object,
        Avatar: pt.object,
    }),
};

export default QuoteBox;