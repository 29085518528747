import React from "react";
import { Breakpoint } from '@arturpol/style-guide/src/components';
import { CalloutOffer, CalloutAbout, Seo, PortfolioSection, CalloutFeatures, PortfolioBanner } from '../../components';
import { useProjects } from '../../util';
import blob4 from '../../images/blob4.svg';
import blob3 from '../../images/blob3.svg';
import blob1 from '../../images/blob1.svg';
import './portfolio.scss';
import * as global from '../../data/global';

const TemplatePortfolio = ({ meta, banner }) => {

    const projects = useProjects();
    const data = {...banner, title: meta.title };
    
    return (
        <>
            <Seo title={meta.seoTitle} description={meta.seoDescription} />
            <PortfolioBanner data={data} />
            <PortfolioSection className="projects-top" projects={projects} pos={0} span={2} callout={
                <CalloutOffer data={global.callout.offer} marginTop={{'m': -14}} marginBottom={{'m': -4}} paddingTop={{'m': 12}} paddingBottom={{'s': 6, 'm': 8}} borderTop={null} />
            }>
                <Breakpoint m={<img className="blob-portfolio-1" src={blob4} alt="" />} />
            </PortfolioSection>
            <PortfolioSection className="projects-middle" projects={projects} pos={2} span={2} callout={
                <CalloutAbout data={global.callout.about} marginTop={{'m': -14}} marginBottom={{'m': -4}} paddingTop={{'m': 10}} paddingBottom={6} borderTop={null} borderBottom={null} />
            }>
                <Breakpoint m={<img className="blob-portfolio-2" src={blob3} alt="" />} />
            </PortfolioSection>
            <PortfolioSection className="projects-bottom" projects={projects} pos={4} span={2} callout={
                <CalloutFeatures data={global.callout.features} marginTop={{'m': -14}} paddingTop={{'m': 12}} paddingBottom={8} borderTop={null} borderBottom={null} />
            }>
                <Breakpoint m={<img className="blob-portfolio-3" src={blob1} alt="" />} />
            </PortfolioSection>
        </>
    );

};

export default TemplatePortfolio;